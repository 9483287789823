<template>
    <div class="author">
        <div class="author-header">
            <prismic-image 
                :img="author.data.image"
                w="60" 
                h="60" 
                class="author-image"
            />
            <div class="author-header-name-email">
                <nuxt-link class="author-name" :to="$prismic.linkResolver(author)">
                    {{ author.data.name }}
                </nuxt-link>
                <div class="author-email-section">
                    <i class="fas fa-envelope" />
                    <a :href="`mailto:${author.data.email}`" class="gray author-email">
                        {{ author.data.email }}
                    </a>
                </div>
            </div>
        </div>
        <div class="rich-text" v-html="$prismic.asHtml(author.data.bio)" />
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        }
    },
    computed: {
        author () {
            return this.$store.state.authors[this.id];
        }
    }
};
</script>
