<template>
    <tr>
        <th>{{ $translate('pros', 'Fördelar') }}</th>
        <td v-for="(card, index) in cards" :key="index">
            <ul>
                <li v-for="(pro, i) in card.data.pros_list" :key="i" class="list-checkmarks margin-bottom-2">
                    <i v-if="pro.pro" class="fas fa-check-circle green" /> <p>{{ pro.pro }}</p>
                </li>
            </ul>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        cards: {
            type: Array,
            required: true
        },
    }
};
</script>