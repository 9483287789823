<template>
    <Section width="md">
        <div class="rich-text">
            <ul v-for="(letter, index) in letters" :key="index" class="padding-bottom-5">
                <h2>{{ letter }}</h2>
                <li v-for="document in getDocuments(letter)" :key="document.id">
                    <nuxt-link :to="$prismic.linkResolver(document.document)" class="text-bold">
                        {{ document.title }}
                    </nuxt-link>
                </li>
            </ul>
        </div>
    </Section>
</template>

<script>
import { orderBy } from 'lodash';
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        items(){
            return orderBy(this.data.items, 'title', 'asc');
        },
        letters() {   
            const letters = this.items
                .map((item) => {
                    return item.title.charAt();
                })
                .filter((letter, index, array) => {
                    return array.indexOf(letter) === index;
                })
                .sort();

            // put numbers to the end
            // eslint-disable-next-line no-constant-condition
            while (true) {
                const letter = letters[0];
                if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(letter)) {
                    letters.push(letters.shift());
                }
                else {
                    break;
                }
            }

            return letters;
        }
    },
    methods: {
        getDocuments(letter) {
            return this.items.filter((item) => {
                return item.title.startsWith(letter);
            });
        }
    }
};

</script>