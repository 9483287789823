<template>
    <a :id="$helpers.formatId(data.id)" />
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>