var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"guide-result",attrs:{"id":"result"}},[(_vm.hasPaymentRemarks && 
            (_vm.paymentRemarksLenders.length > 0 || _vm.paymentRemarksCards.length > 0))?[_c('h2',{staticClass:"payment-remarks-results-header section-header green"},[_vm._v(_vm._s(_vm.$translate('guide_result_header', 'Ditt resultat')))]),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$translate(
                'guide_payment_remarks_result_description', 
                'Inga kreditkort accepterar betalningsanmärkningar, här får du därför lite andra alternativ.'
            ))+"\n        ")]),_vm._v(" "),(_vm.paymentRemarksLenders.length > 0)?[_c('h3',{staticClass:"payment-remarks-results-sub-header"},[_vm._v("\n                "+_vm._s(_vm.$translate(
                    'guide_payment_remarks_result_lenders_header', 
                    'Kontokrediter som passar dig'
                ))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"lenders-list"},_vm._l((_vm.paymentRemarksLenders),function(lender){return _c('lender',{key:lender.id,attrs:{"lender":lender}})}),1)]:_vm._e(),_vm._v(" "),(_vm.paymentRemarksCards.length > 0)?[_c('h3',{staticClass:"payment-remarks-results-sub-header"},[_vm._v("\n                "+_vm._s(_vm.$translate(
                    'guide_payment_remarks_result_cards_header', 
                    'Betalkort som passar dig'
                ))+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"card-list"},_vm._l((_vm.paymentRemarksCards),function(card,index){return _c('card',{key:index,attrs:{"id":card.id}})}),1)]:_vm._e()]:[_c('h2',{staticClass:"results-header section-header green"},[_vm._v(_vm._s(_vm.$translate('guide_result_header', 'Ditt resultat')))]),_vm._v(" "),_c('div',{staticClass:"card-list"},_vm._l((_vm.resultList),function(card,index){return _c('card',{key:index,attrs:{"card":card,"highlighted-text":index === 0 ? _vm.$translate('guide_best_match', 'Bästa matchning') : ''}})}),1)],_vm._v(" "),_c('div',{staticClass:"under-list-utilities"},[_c('feedback-box'),_vm._v(" "),_c('button',{staticClass:"btn btn-md btn-green btn-redo-guide",on:{"click":function($event){return _vm.emitRedoGuide()}}},[_vm._v("\n            "+_vm._s(_vm.$translate('guide_redo_test_button', 'Gör om test'))+"\n        ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }