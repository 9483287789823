<template>
    <div class="feedback-container">
        <expander :title="$translate('guide_feedback_button', 'Lämna feedback')">
            <div v-if="!success">
                <span class="description">
                    {{ $translate('guide_feedback_description', 'Är du nöjd med resultaten du fick uteifrån dina svar? Hjälp oss förbättra guiden genom att dela med om din åsikt!') }} 
                </span>
                <div class="feedback-thumbs" :class="opinion ? `selected selected-${opinion}` : ''">
                    <i class="fas fa-thumbs-up fa-2x positive" :class="{'active': opinion === 'positive'}" @click="setOpinion('positive')" />
                    <i class="fas fa-thumbs-down fa-2x negative" :class="{'active': opinion === 'negative'}" @click="setOpinion('negative')" />
                </div>
                <div v-if="opinion" class="feedback-form">
                    <div class="form-container__input">
                        <label for="feedback">{{ formLabelText }}</label>
                        <textarea v-model="feedback" rows="3" name="feedback" class="feedback-textarea" />
                    </div>
                    <button :disabled="loading" class="btn btn-md btn-green submit-button" @click="submit()">
                        {{ $translate('guide_feedback_submit_button', 'Skicka in') }}
                    </button>
                </div>
                <div v-if="error" class="error">
                    <span>{{ $translate('guide_feedback_error_message', 'Något gick fel, försök igen senare.') }}</span>
                </div>
            </div>
            <div v-else>
                <div class="success-text rich-text" v-html="$prismic.asHtml($translate('guide_feedback_success_message'))" />
            </div>
        </expander>
    </div>
</template>

<script>
import Expander from '@/components/Expander.vue';
export default {
    components: {
        Expander
    },
    data() {
        return {
            opinion: '',
            feedback: '',
            success: false,
            loading: false,
            error: false
        };
    },
    computed: {
        formLabelText() {
            if (this.opinion === 'positive') {
                return this.$translate('guide_feedback_positive_input_label', 'Vad tyckte du var bra? (frivilligt)');
            }
            return this.$translate('guide_feedback_negative_input_label', 'Vad tycker du vi kan förbättra? (frivilligt)');
        }
    },
    methods: {
        submit() {
            const payload = {
                opinion: this.opinion,
                feedback: this.feedback
            };
            if (! this.loading && this.opinion) {
                this.loading = true;
                this.error = false;
            
                this.$axios.post('https://formspree.io/f/moqoqlnj', payload)
                    .then(() => {
                        this.success = true;
                        this.loading = false;
                    })
                    .catch((e) => {
                        // eslint-disable-next-line no-console
                        console.log(e);
                        this.loading = false;
                        this.error = true;
                    });
            }
        },
        setOpinion(opinion) {
            this.feedback = '';
            if (opinion === this.opinion) {
                return this.opinion = '';
            }
            this.opinion = opinion;
        }
    }
};
</script>