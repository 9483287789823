<template>
    <Section
        width="md"
        :class="'bg-' + data.primary.background"
    >
        <CardCategory
            :id="data.primary.card.id"
            :category="data.primary.card_category"
            :category-text="data.primary.card_category_text"
            placement="highlighted"
            class="padding-0"
        />
    </Section>
</template>

<script>
import CardCategory from '@/components/cards/CardCategory.vue';
export default {
    components: { CardCategory },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
};
</script>