<template>
    <Section
        class="rich-text-slice"
        :class="[paddingTop, paddingBottom, 'bg-' + data.primary.background]"
        width="sm"
    >
        <div
            :class="[data.primary.list_icon, `text-${data.primary.text_align}`]"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.content)"
        />
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        paddingTop(){
            if (!this.data.primary.padding_top) {
                return 'padding-top-0';
            }
            return '';
        },

        paddingBottom () {
            if (!this.data.primary.padding_bottom) {
                return 'padding-bottom-0';
            }
            return '';
        }
    }
};
</script>
