<template>
    <div class="banner banner-card flex flex-stretch width-full bg-slgray">
        <div class="container flex">
            <div class="container-inner">
                <h1 class="blue section-header">{{ $prismic.asText(data.primary.h1) }}</h1>
                <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="font width-full section-header" v-html="$prismic.asHtml(data.primary.teaser)" />
                <div v-if="data.primary.show_to_toplist_button" class="width-full flex flex-x-start">
                    <a
                        v-scroll-to="{
                            el: '.list-cards',
                            offset: 0
                        }"
                        class="btn btn-md btn-green"
                        href="#"
                    >
                        {{ $translate('button_show_top_list', 'Visa topplistan') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="container flex">
            <transition name="slide-left" appear>
                <div class="container-inner">
                    <CardBanner :id="data.primary.card.id" :data="data" />
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import CardBanner from '@/components/cards/CardBanner.vue';
export default {
    components: { CardBanner },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>
