<template>
    <Section 
        width="md" 
        class="guides"
        :class="{'margin-top': isFirst}"
    >
        <div 
            v-if="$validateText(data.primary.title)" 
            class="width-full blue section-header"
            v-html="$prismic.asHtml(data.primary.title)"   
        />
        <div class="card-filters">
            <category-box
                v-for="(categoryItem, index) in categoryItems" 
                :key="index"
                :category-item="categoryItem"
                @categoryTag="(categoryTag) => updateCategoryTags(categoryTag)"
            />
        </div>
        
        <search 
            :search-placeholder="$translate('search_page_placeholder', 'Sök efter sida')"
            :default-query="searchQuery"
            @searchQuery="(searchQuery) => updateSearchQuery(searchQuery)"    
        />
        
        <div class="list-all">
            <list-page
                v-for="page in filteredPages" 
                :key="page.id"
                :page="page"
            />
            <div v-if="filteredPages.length === 0">
                <span>
                    {{ $translate('card_search_no_results', 'Din sökning hittade inga guider.') }}
                </span>
            </div>
        </div>
    </Section>
</template>

<script>
import CategoryBox from '@/components/CategoryBox.vue';
import ListPage from '@/components/ListPage.vue';
import Search from '@/components/Search.vue';
import { queryAll, replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: {
        CategoryBox,
        ListPage,
        Search
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        isFirst: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            allPages: []
        };
    },
    async fetch () {
        const predicatesPages = [
            this.$prismic.predicates.at('document.type', 'page'),
            this.$prismic.predicates.any('document.tags', this.allCategoryTags),
        ];
        this.allPages = (await queryAll(this.$prismic.api,
            predicatesPages,
            { 
                orderings: '[document.last_publication_date desc]',
                lang: process.env.PRISMIC_LANG
            }
        ))
            .filter(page => page.id)
            .map(page => {
                return {
                    id: page.id,
                    body: page.data.body,
                    title: page.data.title ? replace(page.data.title.toLowerCase()) : null,
                    image: page.data.image,
                    tags: page.tags,
                    date: page.last_publication_date,
                    author: page.data.author?.id ? this.$store.state.authors[page.data.author.id] : null
                };
            });
    },
    fetchKey() {
        return `guides-${this.data.id}`;
    },
    computed: {
        searchQuery() {
            return this.$route.query.searchGuides?.toLowerCase() ?? '';
        },
        tagFilter() {
            return this.$route.query.categories ? [this.$route.query.categories].flat() : [];
        },
        categoryItems() {
            return this.data.items
                .filter(category => category.filter_tag)
                .map(category => { 
                    category.active = this.tagFilter.includes(category.filter_tag.toLowerCase());
                    // eslint-disable-next-line camelcase
                    category.filter_tag = category.filter_tag.toLowerCase();
                    return category;
                });
        },
        allCategoryTags() {
            return this.categoryItems
                .map(category => category.filter_tag);
        },
        filteredPages() {
            let filteredPages = this.allPages;
            if (this.searchQuery.length) {
                filteredPages = filteredPages
                    .filter(page => page.title?.includes(this.searchQuery));
            }

            if (this.tagFilter.length) {
                filteredPages = filteredPages
                    .filter(page => page.tags
                        .some(tag => this.tagFilter.includes(tag.toLowerCase())
                        )
                    );
            }

            return filteredPages;
        }
    },
    methods: {
        updateCategoryTags(tag) {
            const updatedCategory = this.tagFilter.includes(tag) ? 
                this.tagFilter.filter(item => item !== tag) : 
                [...this.tagFilter, tag];            

            this.$router.replace({
                query: { 
                    ...this.$route.query, 
                    categories: updatedCategory
                }
            });
        },
        updateSearchQuery(searchQuery) {
            let query = null;
            if (searchQuery.length) {
                query = { 
                    ...this.$route.query, 
                    'searchGuides': searchQuery
                };
            }
            else if (this.tagFilter?.length) {
                query = { categories: this.tagFilter };
            }
            this.$router.replace({ query: query });
        }
    }
};
</script>