<template>
    <nuxt-link 
        v-animate="{ threshold: .3, animation: 'up-slide' }"
        class="page-card"
        :to="$prismic.linkResolver(page)"
    >
        <prismic-image 
            v-if="page.image"
            :img="page.image.thumbnail || page.image"
            :alt="page.title"
            w="385"
            class="page-card-image"
        />
        <div class="page-card-body">
            <span v-if="page.title" class="page-card-body-title capitalize-first-letter">
                {{ page.title }}
            </span>
            <div class="page-card-body-footer">
                <i class="far fa-calendar date-icon" />
                <span>{{ $format.date(page.date) }}</span>
            </div>
        </div>
    </nuxt-link>
</template>
<script>
export default {
    props: {
        page: {
            type: Object,
            required: true
        }
    }
};
</script>