<template>
    <Section
        class="list-cards"
        width="md"
        :class="'bg-slgray border-top border-bottom'"
    >
        <h2 v-if="$helpers.filterEmptyElements(data.primary.h2)" class="section-header green text-center">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="section-header width-full" v-html="$prismic.asHtml(data.primary.teaser)" />
        
        <div class="card-categories">
            <div 
                v-for="(category, index) in categories" 
                :key="index" 
                v-scroll-to="{
                    el: '#result',
                    offset: -40
                }"
                class="bxs br-5 padding-4" 
                :class="{'bg-darkblue white text-bold': selected === index, 'bg-white text-lighter': selected !== index}"
                @click="setSelected(index)" 
            >
                <i :class="[category.icon, {'blue': selected !== index}]" />
                <span>{{ category.title }}</span>
            </div>
        </div>

        <div id="result" class="container border-top">
            <div v-for="(category, index) in filteredCategory" :key="`Card${index}`">
                <Card
                    :id="category.card.id"
                    :key="index"
                    :category="category.title"
                    :card-text="category.cardText"
                    placement="list-category-main"
                    :position="category.position"
                />
            </div>
        </div>
        
        <trust-us v-if="!data.primary.disable_trust_us" />
        
        <div class="rich-text bg-white padding-8 margin-top-8">
            <h3 class="h5 margin-top-0">{{ $prismic.asText(data.primary.h3) }}</h3>
            <ul>
                <li v-for="(category, index) in categories" :key="`Summary${index}`" class="text-bold">
                    {{ category.title }}: <nuxt-link :to="$prismic.linkResolver(category.card)">{{ category.card.data.title }}</nuxt-link>
                </li>
            </ul>
        </div>
    </Section>
</template>
<script>
import Card from '@/components/cards/CardBest.vue';
import TrustUs from '@/components/TrustUs.vue';
export default {
    components: {
        Card,
        TrustUs
    },
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            selected: null
        };
    },
    computed: {
        categories() {
            return this.data.items
                .map((item, index) => {
                    return {
                        title: item.category_title,
                        icon: item.category_icon,
                        card: this.$store.state.cards[item.card.id],
                        cardText: item.card_category_text,
                        position: index + 1
                    };
                })
                .filter(category => 
                    category.card && category.card.id && ! category.card.data.lists_disabled
                );
        },
        filteredCategory() {
            if (this.selected === null) {
                return this.categories;
            }

            return this.categories.slice(this.selected, this.selected + 1);
        }
    },
    methods: {
        setSelected(index) {
            
            if (this.selected === index) {
                this.selected = null;
                return;
            }

            this.selected = index;
        }
    }
};
</script>

<style lang="scss" scoped>

    .container {
        margin-top: 40px;
        padding-top: 40px;
        display: grid;
        gap: 40px;
        grid-template-columns: repeat( 1, 1fr );
    }
</style>