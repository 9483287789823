export default {
    computed: {
        insurances () {
            return [
                {
                    title: this.$translate('insurance_purchase_protection', 'Köpskydd'),
                    exists: this.checkInsurance('hasPurchaseProtection')
                },
                {
                    title: this.$translate('insurance_delivery', 'Leveransförsäkring'),
                    exists: this.checkInsurance('hasDeliveryInsurance')
                },
                {
                    title: this.$translate('insurance_travel', 'Reseförsäkring'),
                    exists: this.checkInsurance('hasTravelInsurance')
                },
                {
                    title: this.$translate('insurance_price_guarantee', 'Prisgaranti'),
                    exists: this.checkInsurance('hasPriceGuarantee')
                },
                {
                    title: this.$translate('insurance_comprehensive', 'Allriskförsäkring'),
                    exists: this.checkInsurance('hasComprehensiveInsurance')
                },
                {
                    title: this.$translate('insurance_cancellation_protection', 'Avbeställningsskydd'),
                    exists: this.checkInsurance('hasCancellationProtection')
                },
                {
                    title: this.$translate('insurance_deductible', 'Självriskreducering'),
                    exists: this.checkInsurance('hasDeductibleInsurance')
                },
                {
                    title: this.$translate('insurance_lost_key', 'Nyckelförsäkring'),
                    exists: this.checkInsurance('hasLostKeyInsurance')
                },
                {
                    title: this.$translate('insurance_wrong_fuel', 'Feltankningsförsäkring'),
                    exists: this.checkInsurance('hasWrongFuelInsurance')
                },
                {
                    title: this.$translate('insurance_cancelled_event', 'Evenemangsförsäkring'),
                    exists: this.checkInsurance('hasCancelledEventInsurance')
                },
                {
                    title: this.$translate('insurance_hole_in_one', 'Hole-in-one försäkring'),
                    exists: this.checkInsurance('hasHoleInOneInsurance')
                },
                {
                    title: this.$translate('insurance_id_theft', 'Id-skyddsförsäkring'),
                    exists: this.checkInsurance('hasIdTheftInsurance')
                },
            ];
        },
        insurancesToShow () {
            return this.insurances.filter(insurance => insurance.exists.includes(true));
        },
    },
    methods: {
        checkInsurance (insurance) {
            return this.cards.map(card => {
                return card.model[insurance];
            });
        },
    }
}