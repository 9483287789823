<template>
    <div class="expander br-3 bxs bg-white">
        <div 
            class="expander-button"
            :class="{'expanded': show}"
            role="button"
            @click="show = !show"
        >
            <i v-if="titleIcon" :class="titleIcon" />
            <span class="expander-title">{{ title }}</span>
            <i :class="[{'rotate-active': show}, expanderIcon]" />
        </div>
        <div :class="{'expanded': show}" class="expander-content-wrapper">
            <div class="overflow-hidden">
                <div class="padding">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        titleIcon: {
            type: String,
            required: false,
            default: null
        },
        expanderIcon: {
            type: String,
            required: false,
            default: 'fas fa-chevron-down rotate expander-icon'
        }
    },
    data() {
        return {
            show: false
        };
    }
};
</script>