<template>
    <Section class="table-of-contents-section"> 
        <div class="table-of-contents" :class="{'active': show}">
            <div class="dropdown-header" role="button" @click="show = !show">
                <span>{{ $translate('table_of_contents', 'Sidans innehåll') }}</span>
                <i class="fas fa-chevron-down rotate" :class="{'rotate-active': show}" />
            </div>
            <ul v-show="show">
                <li 
                    v-for="link in links"
                    :key="link.id"
                >
                    <a 
                        v-scroll-to="{el: `#${$helpers.formatId(link.id)}`, offset: -60}"
                        :href="`#${$helpers.formatId(link.id)}`"
                    >
                        {{ link.primary.link_text }}
                    </a>
                </li>
            </ul>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        slices: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            show: false
        };
    },
    computed: {
        links() {
            return this.slices
                .filter(slice => slice.slice_type === 'table_of_contents_link');
        }
    },
};
</script>