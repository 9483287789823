<template>
    <ol class="toplist">
        <li 
            v-for="(item, index) in items" 
            :key="index" 
            class="toplist-wrapper"
        >
            <span
                v-scroll-to="{
                    el: `#bigListItem${dataId + index}`,
                    offset: -100  
                }"
                class="toplist-card-title"
            >
                {{ item.toplistTitle }}
            </span>
            <div 
                v-if="$validateText(item.toplistText)"
                class="toplist-rich-text rich-text"
                v-html="topListText(item)"    
            />
        </li>
    </ol>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        dataId: {
            type: String,
            required: true
        }
    },
    methods: {
        topListText(item) {  
            return this.$prismic.asHtml(item.toplistText)
                .replace(/{interest}/ig, item.model.interestString)
                .replace(/{effectiveInterest}/ig, item.model.effectiveInterestString)
                .replace(/{yearlyCost}/ig, item.model.yearlyCostString)
                .replace(/{maxCredit}/ig, item.model.getMaxCreditString())
                .replace(/{interestFreeDays}/ig, item.model.interestFreeDays);
        }
    }
};
</script>