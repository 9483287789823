<template>
    <Section
        class="info-box"
        width="sm"
        :class="'bg-' + data.primary.background"
    >
        <div class="padding-8 bxs br-3 box bg-white border rich-text">
            <div class="green" v-html="$prismic.asHtml(data.primary.title)" />
            <div v-html="$prismic.asHtml(data.primary.content)" />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>
