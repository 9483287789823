<template>
    <div id="desc" class="flex boxes">
        <div class="box bg-green">
            <div class="box-inner white">
                <h2>{{ $translate('about', 'Om') }} {{ cardModel.title }}</h2>
                <div v-html="$prismic.asHtml(document.data.short_summary)" />
            </div>
        </div>
        <div class="box bg-slgray">
            <div class="box-inner">
                <CardRatings :card-model="cardModel" bar-background="white" />
                <div class="obs width-full border-top margin-top-10 padding-top-4">{{ $translate('obs_card_ratings', 'Ovanstående betyg baseras objektivt på data kring kortet.') }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import CardRatings from '@/components/CardRatings.vue';

export default {
    components: {CardRatings},
    props: {
        document: {
            type: Object,
            required: true
        },
        cardModel: {
            type: Object,
            required: true
        }
    }

};
</script>
