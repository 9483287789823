<template>
    <Section
        class="contact-us bg-white"
        width="sm"
    >
        <div class="text-left width-full">
            <h2 class="blue section-header">{{ $prismic.asText(data.primary.h2) }}</h2>
        </div>

        <div class="content-box padding-8 bg-slgray bxs border-green-left margin-bottom-6" v-html="$prismic.asHtml(data.primary.content_box)" />

        <div class="flex width-full">
            <span class="width-full margin-bottom-2">{{ $translate('email', 'E-postadress') }}</span>
            <span class="width-full green">{{ data.primary.email }}</span>
        </div>

        <div class="width-full border-bottom margin-y-8" />
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>
