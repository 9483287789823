export default {
    computed: {
        mobilePayments() {
            return [
                {
                    title: 'Apple pay',
                    key: 'hasApplePay'
                },
                {
                    title: 'Samsung pay',
                    key: 'hasSamsungPay'
                },
                {
                    title: 'Google pay',
                    key: 'hasGooglePay'
                },
            ];
        }
    }
};