<template>
    <Section
        id="faq"
        class="faq bg-gray"
        width="sm"
    >
        <h2 class="section-header width-full text-left blue">
            {{ $translate('cr_questions', 'Vanliga frågor om {card}')
                .replace('{card}', document.data.title) 
            }}
        </h2>
        <div class="width-full" itemscope itemtype="https://schema.org/FAQPage">
            <question-and-answer
                v-for="(iterator, index) in faqs"
                :key="index"
                class="width-full"
                :question="$prismic.asText(iterator.question)"
                :answer="$prismic.asHtml(iterator.answer)"
            />
        </div>
    </Section>
</template>

<script>
import { QuestionAndAnswer } from '@swegaming-ab/vue-components';

export default {
    components: {QuestionAndAnswer},
    props: {
        document: {
            type: Object,
            required: true
        },
        faqs: {
            type: Array,
            required: true
        }
    }

};
</script>