<template>
    <div v-if="settings.enable_trust_us_card_list" class="trust-us">
        <expander 
            class="trust-us-expander" 
            :title="settings.trust_us_expander_title"    
            title-icon="fa-solid fa-shield-check fa-lg title-icon"
        >
            <div 
                v-if="$validateText(settings.trust_us_expander_text)" 
                class="rich-text trust-us-rich-text"
                v-html="$prismic.asHtml(settings.trust_us_expander_text)" 
            />
        </expander>
    </div>
</template>

<script>
import Expander from '@/components/Expander.vue';
export default {
    components: { Expander },
    computed: {
        settings() {
            return this.$store.state.settings;
        }
    }
};
</script>