<template>
    <Section
        class="list-posts"
        width="md"
        :class="'bg-' + data.primary.background"
    >
        <h2 v-if="$helpers.filterEmptyElements(data.primary.h2)" class="section-header green m-center">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="section-header width-full" v-html="$prismic.asHtml(data.primary.teaser)" />

        <Post
            v-for="(post, index) in allPosts"
            :key="index"
            :post="post"
        />
    </Section>
</template>

<script>
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
import Post from '@/components/Post.vue';
export default {
    components: {
        Post
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            allPosts: [],
        };
    },
    async fetch () {
        this.allPosts = await queryAll(this.$prismic.api, 
            this.$prismic.predicates.at('document.type', 'post'),
            { 
                orderings: '[document.first_publication_date desc]',
                lang: process.env.PRISMIC_LANG
            }
        );
    },
    fetchKey() {
        return `all-posts-${this.data.id}`;
    }
};
</script>
