<template>
    <Section class="quote" width="md">
        <div class="container">
            <span>{{ $prismic.asText(data.primary.quote) }}</span>
            <span v-if="data.primary.quote_by">- {{ data.primary.quote_by }}</span>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>
