<template>
    <tr>
        <th>{{ insurance.title }}</th>
        <td v-for="(cardInsurance, i) in insurance.exists" :key="i" v-html="checkmarkBoolean(cardInsurance)" /> 
    </tr>
</template>

<script>
export default {
    props: {
        insurance: {
            type: Object,
            required: true
        },
        checkmarkBoolean: {
            type: Function,
            required: true
        }
    },
};
</script>