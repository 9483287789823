<template>
    <nuxt-link 
        :to="$prismic.linkResolver(document)"
        class="container-box" 
    >
        <prismic-image 
            class="document-image"
            :img="image(document.data.image)"
            :alt="document.data.title"
            w="600"
        />
        <div class="container-box-body">
            <h3 class="body-title"> 
                {{ document.data.title }}
            </h3>
            <div>
                <span class="read-more">{{ $translate('cta_read_more', 'Läs mer') }}</span>
                <i class="fas fa-arrow-right" />
            </div>
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    methods: {
        image(image) {
            return image?.thumbnail ? image.thumbnail : image;
        }
    }
};
</script>