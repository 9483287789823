
<template>
    <div class="read-time">
        <i class="far fa-clock" />

        <span class="read-time__minutes">
            {{ $translate('wpm_read', '{readTime} min')
                .replace('{readTime}', formatReadMinutes) }} 
        </span>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            wpmRead: 183
        };
    },
    computed: {
        readTime() {
            const richTextSlices = this.data.filter(item => item.slice_type === 'rich_text');
            const wordCount = richTextSlices.reduce((acc, slice) => {
                const words = this.$prismic.asText(slice.primary.content).split(' ');
                return acc + words.length;
            }, 0);

            return wordCount / this.wpmRead;
        },
        formatReadMinutes() {
            if (this.readTime < 1) {
                return '< 1';
            }

            return this.$format.number(this.readTime);
        }
    }
};
</script>
