<template>
    <Section
        class="contact-table padding-y-0"
        width="sm"
    >
        <table>
            <tr class="head">
                <td>{{ $translate('table_name_and_card_issuer', 'Namn & kortutgivare') }}</td>
                <td>{{ $translate('contact_information', 'Kontaktuppgifter') }}</td>
            </tr>
            <tr
                v-for="(card, index) in cards"
                :key="index"
            >
                <td>
                    <nuxt-link :to="$prismic.linkResolver(card)">{{ card.data.title }}</nuxt-link>
                </td>
                <td>
                    <span v-if="card.data.core_api.brand_data.company_phone">{{ card.data.core_api.brand_data.company_phone }}</span>
                    <span v-else class="subtile">{{ $translate('table_missing_phone_number', 'Telefonnummer saknas') }}</span>

                    <span v-if="card.data.core_api.brand_data.company_email">{{ card.data.core_api.brand_data.company_email }}</span>
                    <span v-else class="subtile">{{ $translate('table_missing_email', 'E-postadress saknas') }}</span>
                </td>
            </tr>
        </table>
    </Section>
</template>

<script>
import { orderBy } from 'lodash';
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        }
    },
    computed: {
        cards(){
            return orderBy(this.$store.state.cards, 'data.title', 'asc');
        }
    }
};
</script>

<style lang="scss" scoped>
    @import './assets/css/colors';

    .contact-table {
        table {
            width: 100%;

            tr.head {
                font-weight: bold;
                font-size: 16px;
            }

            tr {
                border-bottom: 2px solid rgba(0,0,0,.03);
                font-size: 14px;

                td {
                    padding: 5px;
                    line-height: 20px;

                    a {
                        color: $green;

                        &:hover {
                            text-decoration: none;
                        }
                    }

                    span {
                        display: block;
                    }

                    .subtile {
                        color: #999;
                        font-style: italic;
                        font-size: 13px;
                    }
                }
            }

            tr:last-child { border: 0; }
        }
    }
</style>
