<template>
    <div class="card card-category padding-y-4 width-full">
        <div class=" flex">
            <div class="flexx">
                <PrismicImage
                    :img="card.data.image"
                    w="160"
                    h="100"
                />

                <ButtonTarget
                    size="sm"
                    background="orange"
                    class="block margin-top-2 text-center"
                    :text="$translate('button_apply', 'Till ansökan')"
                    icon="fas fa-lock"
                    :link="card"
                    :placement="placement"
                    :position="position"
                />
            </div>

            <div class="flex-1 padding-6">
                <div class="width-full flex flex-x-start header m-center">
                    <h5 v-if="category" class="margin-right-2">
                        {{ category }}: 
                    </h5>
                    <nuxt-link 
                        :to="$prismic.linkResolver(card)" 
                        class="text-bold green"
                    >
                        {{ card.data.title }}
                    </nuxt-link>
                </div>

                <div 
                    class="margin-y-4 rich-text" 
                    v-html="$prismic.asHtml(categoryText)" 
                />

                <div class="width-full read-more">
                    <span class="text-lighter">
                        {{ $translate('button_read_more', 'Läs mer') }}: 
                    </span>
                    <nuxt-link 
                        :to="$prismic.linkResolver(card)" 
                        class="text-bold blue margin-left-1 line-height-20"
                    >
                        {{ $translate('cr_review_of', 'Recension av') }} {{ card.data.title }}
                    </nuxt-link>
                </div>
            </div>
        </div>
        <div 
            v-if="$validateText(model.complianceExample)" 
            class="text-compliance padding-top-0"
        >
            {{ model.complianceExample }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true,
            default: String
        },
        category: {
            type: String,
            required: false,
            default: 'Kategori'
        },
        categoryText: {
            type: Array,
            required: false,
            default: () => []
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    data () {
        return {
            expand: false
        };
    },
    computed: {
        card(){
            return this.$store.state.cards[this.id];
        },
        model(){
            return new this.$models.CreditCard(this.card, this.$store);
        }
    }
};
</script>
<style lang="scss" scoped>
    .line-height-20 {
        line-height: 20px;
    }
</style>