<template>
    <div class="search">
        <i class="fas fa-search search-icon" />
        <input 
            v-model="searchQuery" 
            class="form-control" 
            :placeholder="searchPlaceholder"
            @keydown.esc="searchQuery = ''"
        >
        <i 
            v-if="searchQuery.length > 0" 
            class="fas fa-xmark search-xmark" 
            @click="searchQuery = ''"
        />
    </div>
</template>
<script>
export default {
    props: {
        searchPlaceholder: {
            type: String,
            required: true
        },
        defaultQuery: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            searchQuery: this.defaultQuery || '',
        };
    },
    watch: {
        searchQuery() {
            this.$emit('searchQuery', this.searchQuery);
        }
    }
};
</script>