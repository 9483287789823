<template>
    <Section
        class="list-authors"
        width="sm"
        :class="'bg-' + data.primary.background"
    >
        <div class="width-full text-left">
            <h2 class="section-header blue">{{ $prismic.asText(data.primary.h2) }}</h2>
            <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="section-header width-full" v-html="$prismic.asHtml(data.primary.teaser)" />
        </div>

        <Author
            v-for="(author, index) in authors"
            :id="author.id"
            :key="index"
        />
    </Section>
</template>

<script>
import Author from '@/components/Author.vue';
export default {
    components: {
        Author
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        authors () {
            return this.$store.state.authors;
        }
    }
};
</script>
