<template>
    <div class="big-list-item">
        <div class="card-top-section">
            <nuxt-link
                :to="$prismic.linkResolver(item.card)"
                class="card-link h3"
            >
                <h3 class="title">
                    {{ item.h3 }}
                </h3>
                <PrismicImage
                    :img="item.card.data.image"
                    class="card-image"
                    w="160"
                />
            </nuxt-link>
            <div 
                v-if="$validateText(item.teaser)"    
                class="rich-text" 
                v-html="$prismic.asHtml(item.teaser)"
            />
        </div>

        <table-data 
            v-if="dataType === 'card-data'" 
            :table-data="item.tableData"    
        />

        <pros-list 
            v-else 
            :pros-list="item.prosList"    
        />

        <ButtonTarget
            :link="item.card"
            :text="$translate('button_apply', 'Till ansökan')"
            icon="fas fa-chevrons-right fa-2xs white"
            background="orange"
            size="md"
            class="card-out-link"
        />
    </div>
</template>

<script>
import TableData from './TableData.vue';
import ProsList from './ProsList.vue';
export default {
    components: {
        TableData,
        ProsList
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        dataType: {
            type: String,
            required: true
        }
    }
};
</script>