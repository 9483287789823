<template>
    <Section width="sm padding-2" class="author-box">
        <div class="author-box__header">
            <prismic-image 
                :img="author.data.image" 
                w="60" 
                h="60"
            />
            <div>
                <nuxt-link :to="$prismic.linkResolver(author)" class="blue text-bold author-name">
                    {{ author.data.name }}
                </nuxt-link>
                <span class="gray margin-top-2 small">
                    {{ $translate('published', 'Publicerad') }} 
                    {{ $format.date(document.first_publication_date) }}
                </span>
            </div>
        </div>
        <div class="author-box__body rich-text">
            <div class="margin-bottom-5 bio" v-html="$prismic.asHtml(author.data.bio)" />
            <span v-if="showLastUpdated" class="gray last-updated">
                {{ $translate('updated', 'Uppdaterad') }} 
                {{ $format.date(document.last_publication_date) }}
            </span>
            <div v-if="showSources" class="sources-container">
                <h5>{{ $translate('sources', 'Källor') }}</h5>
                <ul>
                    <li v-for="source in sources" :key="source">
                        <a :href="source">
                            {{ source }}
                        </a>
                    </li>
                </ul>
            </div>
            <div v-if="editor" class="author-publication-container">
                <div class="author-publication-body">
                    <PrismicImage
                        class="author-publication-image"
                        :img="editor.data.image"
                        h="35"
                        w="35"
                    />
                    <nuxt-link :to="$prismic.linkResolver(editor)" class="author-publication-name">
                        <p 
                            v-html="
                                $translate('reviewed_by_editor', 'granskad av {editor}')
                                    .replace('{editor}', `<span>${editor.data.name}</span>`)"
                        />
                    </nuxt-link>
                </div>
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        authorId() {
            return this.document.data.author.id;
        },
        author() {
            return this.$store.state.authors[this.authorId];
        },
        editor() {
            return this.$store.state.authors[this.document.data.editor?.id];
        },
        showLastUpdated() {
            return this.$format.date(this.document.first_publication_date) !==
                this.$format.date(this.document.last_publication_date);
        },
        sources() {
            if (! this.document.data.sources) {
                return [];
            }

            return this.document.data.sources
                .map(iterator => iterator.source)
                .filter(source => Boolean(source))
                .map(source => source.trim());
        },
        showSources() {
            return this.sources.length > 0;
        }
    }
};
</script>