<template>
    <Section
        class="banner banner-link"
        width="sm"
        :class="'bg-' + data.primary.background"
    >
        <div class="flex-1 flex inner" :class="flexAlign">
            <h1 class="section-header width-full">{{ $prismic.asText(data.primary.h1) }}</h1>
            <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="width-full rich-text" v-html="$prismic.asHtml(data.primary.teaser)" />

            <transition name="pop" appear>
                <ButtonNuxt
                    v-if="data.primary.link_text"
                    :link="data.primary.link"
                    :text="data.primary.link_text"
                    size="md"
                    background="green"
                    class="margin-top-8 m-margin-top-4"
                />
            </transition>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            deault: () => {}
        }
    },
    computed: {
        flexAlign(){
            if (this.data.primary.align_content === 'left') {
                return 'flex-x-start';
            }
            else if (this.data.primary.align_content === 'right') {
                return 'flex-x-end';
            }
            return '';
        }
    }
};
</script>
