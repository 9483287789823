<template>
    <div>
        <div class="card card-banner flex br-3 bxs bg-white border padding-8">
            <nuxt-link :to="$prismic.linkResolver(card)" class="card-img">
                <PrismicImage
                    :img="card.data.image"
                    w="200"
                    h="126"
                    :lazy="false"
                />
            </nuxt-link>

            <div class="text-center width-full margin-y-4">
                <div v-if="$validateText(data.primary.card_category)" class="cat">
                    {{ data.primary.card_category }}:
                </div>
                <nuxt-link :to="$prismic.linkResolver(card)" class="h5 text-bold green">
                    {{ card.data.title }}
                </nuxt-link>
            </div>

            <div 
                v-if="$validateText(data.primary.card_info)" 
                class="rich-text card-info-text" 
                v-html="$prismic.asHtml(data.primary.card_info)" 
            />
            <ButtonTarget
                :link="card"
                placement="featured"
                :text="$translate('button_apply', 'Till ansökan')"
                size="md"
                background="orange"
                class="margin-top-4"
                icon="fas fa-lock"
            />
        </div>
        <div 
            v-if="$validateText(model.complianceExample)" 
            class="text-compliance padding-x-1"
        >
            {{ model.complianceExample }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {}
        },
        id: {
            type: String,
            required: true,
            default: String
        }
    },
    data(){
        return {
            expand: false,
            showBg: false,
            showImg: false
        };
    },
    computed: {
        card(){
            return this.$store.state.cards[this.id];
        },
        model(){
            return new this.$models.CreditCard(this.card, this.$store);
        }
    }
};
</script>
<style lang="scss" scoped>
    .card-info-text::v-deep {
        text-align: center;
        
        ul, ol {
            text-align: left !important;
            margin-left: 0;
            @media screen and (min-width: 600px) {
                margin-left: 1.5rem;
            }
        }
    }
</style>
