<template>
    <table class="table">
        <tbody>
            <tr 
                v-for="(row, indexRow) in rows"
                :key="indexRow"
                class="table-header table-column"
            >
                <th>{{ tableTitles[indexRow] }}</th>
                <td 
                    v-for="(data, index) in row" 
                    :key="index" 
                    class="rich-text no-margin"
                    v-html="$prismic.asHtml(data)"
                />
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        tableTitles: {
            type: Array,
            required: true
        },
        tableValues: {
            type: Array,
            required: true
        }
    },
    computed: {
        rows() {
            const rows = [];

            for (let i = 0; this.tableTitles.length > i; i++) {
                const rowValues = this.tableValues.map(row => {
                    return row[i];
                });
                rows.push(rowValues);
               
            }
            return rows;
        }
    }
};
</script>