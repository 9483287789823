<template>
    <Section
        class="indexed bg-white"
        width="sm"
    >
        <div class="width-full flex section-headerx">
            <span class="index">{{ data.primary.index }}</span>
            <h2 v-if="$helpers.filterEmptyElements(data.primary.h2)" class="margin-6 m-center green flex-1">{{ $prismic.asText(data.primary.h2) }}</h2>
        </div>

        <ul class="width-full">
            <li
                v-for="(section, index) in data.items"
                :key="index"
                class="flex flex-keep flex-y-start"
            >
                <span class="list-index">{{ data.primary.index }}.{{ index+1 }}</span>
                <div class="flex-1" v-html="$prismic.asHtml(section.section)" />
            </li>
        </ul>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>
