<template>
    <div class="latest-posts">
        <h2 class="latest-posts-title">
            {{ title }}
        </h2>
        <nuxt-link 
            v-for="(authorArticle, index) in authorArticles.slice(0, limit)" 
            :key="index"
            :to="$prismic.linkResolver(authorArticle)"
            class="posts-wrapper" 
        >
            <prismic-image 
                v-if="authorArticle.data.image.url"
                :img="authorArticle.data.image.thumbnail || authorArticle.data.image"
                class="posts-image"
                w="385"
            />
            <div class="posts-preamble-wrapper">
                <span class="posts-title">{{ authorArticle.data.title }}</span>
                <p class="rich-text posts-preamble">{{ latestPostPreamble(authorArticle) }}</p>
                <span class="posts-date">
                    {{ $translate('published', 'Publicerad') }}
                    {{ $format.date(authorArticle.first_publication_date) }}
                </span>
            </div>
        </nuxt-link>
        <div class="load-posts">
            <Button
                v-if="limit <= authorArticles.length"
                :text="$translate('show_more', 'Visa mer')"
                icon="fas fa-chevron-down"
                background="gray"
                size="sm"
                @click="updateLimit(limit + 15)"
            />
            <Button
                v-if="limit > 5"
                :text="$translate('show_less', 'Visa mindre')"
                icon="fas fa-chevron-up"
                background="gray"
                size="sm"
                @click="updateLimit(5)"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        authorArticles: {
            type: Array,
            required: false,
            default: null
        },
        title: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            limit: 5
        };
    },
    methods: {
        latestPostPreamble(post) {
            return post.data.content[0].text;
        },
        updateLimit(value) {
            this.limit = value;
        }
    }
};
</script>