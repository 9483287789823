<template>
    <div>
        <Section
            class="wrapper wrapper-post bg-white"
            width="sm"
        >
            <h1 class="post-header blue section-header widt-full">{{ document.data.title }}</h1>
            <prismic-image 
                :img="document.data.image"
                class="post-image"
                w="800"
            />
            <div class="rich-text post-content" v-html="$prismic.asHtml(document.data.content)" />
        </Section>
        <AuthorBox v-if="showAuthorBox" :document="document" />
    </div>
</template>

<script>
import AuthorBox from '@/components/AuthorBox.vue';
export default {
    components: {
        AuthorBox
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        showAuthorBox() {
            return this.document.data.author &&
                this.document.data.author.id;
        }
    }
};
</script>