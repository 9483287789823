<template>
    <div v-if="card" :class="{'highlighted': $validateText(highlightedText)}">
        <offer 
            v-if="card.data.offer" 
            :offer-text="card.data.offer"
            :link="card"
            :placement="placement + '-offer'"
            :position="position"
        />
        <div class="card card-default width-full border bxs br-3 flex"> 
            <div class="card-body width-full flex flex-stretch bg-white padding-4">
                <nuxt-link :to="$prismic.linkResolver(card)" class="card-img padding-6 m-margin-top-6">
                    <PrismicImage
                        :img="card.data.image"
                        w="200"
                        h="126"
                    />
                </nuxt-link>
                <div class="flex-3 flex flex-stretch">
                    <div class="flex-1 flex flex-x-start flex-y-start m-center padding-6">
                        <h5 class="card-title">{{ cardModel.title }}</h5>
                        <div 
                            v-if="! $store.state.settings.norway_compliance"
                            class="rating margin-bottom-4 margin-top-2 width-full"
                        >
                            <i
                                v-for="i in 5"
                                :key="i"
                                :class="{ yellow: i <= $helpers.getCardStarsCount(card) }"
                                class="fas fa-star"
                            />
                        </div>

                        <ul class="pros text-left">
                            <li v-for="(iterator, index) in card.data.pros_list" :key="index" class="text-lighter">
                                {{ iterator.pro }}
                            </li>
                        </ul>
                    </div>
                    <div class="flex-1 flex padding-6">
                        <CardShortInfo :card-model="cardModel" />
                        <div class="margin-top-4 text-center">
                            <div class="expand green text-lighter" @click="expand =! expand">{{ $translate('button_more_info', 'Mer information') }} <i class="fas fa-chevron-down margin-left-2" /></div>
                        </div>
                    </div>
                </div>
            </div>


            <div v-show="expand" class="flex flex-y-start width-full more-info bg-white padding-top-4">
                <div class="flex-1 padding-6">
                    <CardInfo :card-model="cardModel" header-tag="h6" />
                </div>

                <div class="flex-1 padding-6">
                    <CardRatings :card-model="cardModel" bar-background="slgray" header-tag="h6" />
                </div>
            </div>

            <div class="width-full bg-slgray padding-4 flex flex-y-start buttons">
                <ButtonTarget
                    :link="card"
                    :text="$translate('button_apply', 'Till ansökan')"
                    :placement="placement"
                    :position="position"
                    size="md"
                    background="orange"
                    class="flex"
                    icon="fas fa-lock"
                />

                <ButtonNuxt
                    :link="card"
                    :text="$translate('button_read_review', 'Läs hela recensionen')"
                    size="md"
                    background="green-text"
                    class="flex"
                />
            </div>

            <div class="add-compare flex flex-x-start text-lighter" @click="toggleCompare(card.id)">
                <span v-if="!cardIsAdded">{{ $translate('compare_add_card', 'Jämför kort') }}</span>
                <span v-else>{{ $translate('compare_remove_card', 'Ta bort kort') }}</span>

                <div class="checkbox margin-left-2 flex">
                    <i v-if="cardIsAdded" class="fas fa-check green" />
                </div>
            </div>

            <div v-if="$validateText(highlightedText)" class="highlighted-badge"> 
                {{ highlightedText }}
            </div>
        </div>

        <div
            v-if="$store.state.settings.norway_compliance"
            class="card-compliance"
        >
            {{ card.data.core_api.brand_data.compliance_example }}
        </div>
    </div>
</template>

<script>
import { includes } from 'lodash';
import CardRatings from '@/components/CardRatings.vue';
import CardInfo from '@/components/CardInfo.vue';
import CardShortInfo from '@/components/CardShortInfo.vue';
import Offer from '@/components/Offer.vue';
export default {
    components: { 
        CardRatings, 
        CardInfo, 
        CardShortInfo,
        Offer,
    },
    props: {
        card: {
            type: Object,
            required: true,
        },
        highlightedText: {
            type: String,
            required: false,
            default: null
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    data(){
        return {
            expand: false
        };
    },
    computed: {
        // card(){
        //     return this.$store.state.cards[this.id];
        // },

        cardModel(){
            return new this.$models.CreditCard(this.card, this.$store);
        },

        compare(){
            return includes(this.$store.state.cardsToCompare, this.card.id);
        },

        cardIsAdded(){
            return includes(this.$store.state.cardsToCompare, this.card.id);
        },
    },
    methods: {
        toggleCompare(id){
            if (this.compare) {
                this.$store.commit('REMOVE_CARD_FROM_COMPARE', id);
            }
            else {
                this.$store.commit('ADD_CARD_TO_COMPARE', id);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .card-title {
        width: 100%;
    }
    .card-compliance {
        font-size: 14px;
        margin-top: 10px;
        font-size: 13px;
        font-weight: lighter;
    }
</style>
