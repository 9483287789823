<template>
    <Page v-if="document.type === 'page'" :document="document" />
    <Post v-else-if="document.type === 'post'" :document="document" />
    <CreditCard v-else-if="document.type === 'credit_card'" :document="document" />
    <Author v-else-if="document.type === 'author'" :document="document" />
</template>
<script>


// Pages
import getDocument from '@/plugins/get-document';

import Page from '@/components/pages/Page.vue';
import Post from '@/components/pages/Post.vue';
import CreditCard from '@/components/pages/CreditCard/index.vue';
import Author from '@/components/pages/Author.vue';

export default {
    components: {
        Page,
        Post, 
        CreditCard,
        Author
    },
    layout: 'site',
    /**
    * asyncData used on server side (only dev)
    */
    async asyncData({$prismic, route, error}) {
        try {
            return await getDocument($prismic, route.path, error);
        }
        catch (e) {
            error({statusCode: 404, message: 'Not found'});
        }
    },
    head() {
        return {
            title: this.document.data.seo_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.document.data.seo_description || ''
                }
            ]
                .concat(this.document.data.is_landing_page === true ? [{ name: 'robots', content: 'noindex' }] : []),
            link: [
                {
                    rel: 'canonical',
                    href: this.$prismic.linkResolver(this.document, true)
                },
            ]
                .concat(this.hreflangs)
        };
    },
};
</script>
