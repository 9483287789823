<template>
    <Section 
        :width="sliceWidth" 
        class="banner banner-image"
        :class="{'bg-slgray': sliceWidth === 'md'}"    
    >
        <h1  
            v-if="$validateText(data.primary.h1)"
            class="section-header blue"
        >
            {{ data.primary.h1 }}
        </h1>
        <div 
            v-if="$validateText(data.primary.teaser)"
            class="rich-text" 
            v-html="$prismic.asHtml(data.primary.teaser)"
        />
        <prismic-image 
            v-if="data.primary.image.url"
            :img="data.primary.image"
            :alt="data.primary.image.alt"
            class="image"
            :w="imageWidth"
        />
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        sliceWidth() {
            return this.data.primary.slice_width?.startsWith('md') ? 'md' : 'sm';
        },
        imageWidth() {
            return this.sliceWidth === 'sm' ? '800' : '1000';
        }
    }
};
</script>
