<template>
    <Section 
        v-if="data.primary.link.id" 
        :width="sectionWidth || 'sm'" 
    >
        <div class="text-left width-full">
            <nuxt-link 
                :to="$prismic.linkResolver(data.primary.link)"
                class="btn button"
                :class="`btn-${data.primary.button_color} btn-${data.primary.button_size}`"
            >
                {{ data.primary.link_text }}
            </nuxt-link>
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
    },
    computed: {
        sectionWidth() {
            return this.data.primary.section_width;
        }
    }
};
</script>
<style lang="scss" scoped>
    .section {
        padding-top: 0;
    }
    .button {
        display: inline-block;
    }
</style>