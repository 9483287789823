<template>
    <Section
        id="other"
        class="other-cards padding-top-0 bg-white"
        width="sm"
    >
        <div class="text-left width-full rich-text">
            <h2 class="margin-top-0 m-center">{{ $translate('cr_other_popular_cards', 'Andra populära kreditkort') }}</h2>
        </div>
        <div class="width-full flex flex-x-start cards">
            <DisplayOtherCard 
                v-for="id in otherCardIds" 
                :id="id" 
                :key="id"
            />
        </div>
    </Section>
</template>

<script>
import DisplayOtherCard from './DisplayOtherCard.vue';

export default {
    components: {DisplayOtherCard},
    props: {
        document: {
            type: Object,
            required: true
        },
        otherCardIds: {
            type: Array,
            required: true
        }
    },
};
</script>