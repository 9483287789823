<template>
    <div class="two-boxes width-full flex flex-stretch">
        <div
            class="container"
            :class="['bg-' + box1.background, { img: box1.image.url }, box1.imageContainerClass]"
        >
            <PrismicImage
                v-if="box1.image.url"
                :img="box1.image"
                :w="box1.imageWidth"
            />

            <div
                v-else
                class="rich-text"
                v-html="$prismic.asHtml(box1.content)"
            />
        </div>

        <div
            class="container"
            :class="['bg-' + box2.background, { img: box2.image.url }, box2.imageContainerClass]"
        >
            <PrismicImage
                v-if="box2.image.url"
                :img="box2.image"
                :w="box2.imageWidth"
            />
            <div
                v-else
                class="rich-text"
                v-html="$prismic.asHtml(box2.content)"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        box1() {
            return {
                background: this.data.primary.box_1_background,
                image: this.data.primary.box_1_image,
                imageWidth: this.data.primary.box_1_image_style === 'with_spacing' ? '480' : '960',
                imageContainerClass: this.data.primary.box_1_image_style === 'with_spacing' ? 'with-spacing' : 'full_width',
                content: this.data.primary.box_1_content
            };
        },
        box2() {
            return {
                background: this.data.primary.box_2_background,
                image: this.data.primary.box_2_image,
                imageWidth: this.data.primary.box_2_image_style === 'with_spacing' ? '480' : '960',
                imageContainerClass: this.data.primary.box_2_image_style === 'with_spacing' ? 'with-spacing' : 'full_width',
                content: this.data.primary.box_2_content
            };
        }
    },
};
</script>
