<template>
    <Section 
        class="alternate-grid"
        width="md"    
    >   
        <h2 v-if="data.primary.h2" class="blue">
            {{ data.primary.h2 }}
        </h2>
        <div 
            v-if="$validateText(data.primary.teaser)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.teaser)"     
        />
        <div 
            v-for="(item, index) in items"
            :key="index"
            class="alternate-grid-row"
        >
            <div
                class="alternate-grid-col-text"
                :class="(index % 2 === 0) && !data.primary.disable_alterante_order ? 'alternate-even' : 'alternate-odd'"
            >
                <h3 v-if="item.title">
                    {{ item.title }}
                </h3>
                <div 
                    v-if="$validateText(item.text)"
                    class="rich-text"
                    v-html="$prismic.asHtml(item.text)"
                /> 
            </div>
            <div
                class="alternate-grid-col-image"
                :class="(index % 1 === 0) && !data.primary.disable_alternate_order ? 'alternate-even' : 'alternate-odd'"
            >
                <prismic-image 
                    v-if="item.image.url" 
                    :img="item.image" 
                    :w="imageWith(item.image.dimensions.width)" 
                />
            </div>
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            return this.data.items.filter(item => item.image.url);
        }
    },
    methods: {
        imageWith(imageWidth) {
            return imageWidth <= '550' ? 'auto' : '550';
        }
    }
};
</script>