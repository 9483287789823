<template>
    <Section
        class="media-section"
        :class="'bg-' + data.primary.background"
        width="md"
    >
        <h2 
            v-if="$validateText(data.primary.h2)" 
            class="section-header m-center"
        >
            {{ $prismic.asText(data.primary.h2) }}
        </h2>
        <div 
            v-if="$validateText(data.primary.teaser)" 
            class="text-center section-header width-full media-section-teaser rich-text" 
            v-html="$prismic.asHtml(data.primary.teaser)"
        />

        <div 
            class="container" 
            :class="{'container-video': isVideo}"
            @click="showVideo = true"
        >
            <div
                v-if="isVideo && showVideo"
                class="video"
                v-html="data.primary.video.html"
            />

            <PrismicImage 
                v-if="!isVideo" 
                :img="data.primary.image"
                class="img"    
            />

            <i 
                v-if="isVideo && !showVideo" 
                class="fab fa-youtube white"
            />
        </div>
    </Section>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            showVideo: false
        };
    },
    computed: {
        isVideo () {
            if (this.data.primary.video.html) {
                return true;
            }
            return false;
        }
    }
};
</script>
