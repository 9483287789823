<template>
    <ul class="pros-list">
        <li v-for="(pro, proIndex) in prosList" :key="proIndex">
            <i class="fa-solid fa-check-circle icon" />
            <p class="pro">
                {{ pro }}
            </p>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        prosList: {
            type: Array,
            required: true
        }
    }
};
</script>