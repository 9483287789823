<template>
    <table class="table">
        <tbody>
            <tr 
                v-for="(row, rowIndex) in tableData" 
                :key="rowIndex"
            >
                <th>{{ row.title }}</th>
                <td v-html="row.value" />
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props: {
        tableData: {
            type: Array,
            required: true
        }
    }
};
</script>