<template>
    <ul class="short-info list-border-bottom width-full">
        <li 
            v-for="(info, index) in cardInfo"
            :key="index" 
            class="flex flex-keep padding-2"
        >
            <span class="flex-1 text-right text-bold margin-right-2">{{ info.title }}:</span>
            <span class="flex-1 text-left text-lighter" v-html="info.value" /> 
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        cardModel: {
            type: Object,
            required: true
        }
    },
    computed: {
        cardInfo() {
            const cardInfo = [
                {
                    title: this.$translate('card_info_yearly_fee', 'Årsavgift'),
                    value: this.cardModel.yearlyCostHtml
                }
            ];
            if (this.cardModel.isCredit) {
                cardInfo.push(
                    {
                        title: this.$translate('card_info_highest_credit', 'Hösta kredit'),
                        value: this.cardModel.getMaxCreditString()
                    },
                    {
                        title: this.$translate('card_info_effective_interest', 'Effektiv ränta'),
                        value: this.cardModel.effectiveInterestString
                    },
                    {
                        title: this.$translate('card_info_interest_free', 'Räntefritt'),
                        value: this.cardModel.getInterestFreeDaysString(this.$translate('bd_days', '{days} dagar'))
                    },
                );
            } 
            else {
                cardInfo.push(
                    {
                        title: this.$translate('card_info_highest_credit', 'Hösta kredit'),
                        value: `<span class='padding-x-1 bg-blue white br-2'>${this.$translate('debit_card', 'Debitkort')}</span>`
                    },
                );
            }
            return cardInfo;
        }
    }
};
</script>
