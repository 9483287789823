<template>
    <Ucr
        :core-id="Number(document.data.core_api.core_data.core_id)"
        :core-ratings="document.data.core_api.ratings"
        :form-title="$translate('ucr_form_title', 'Skriv ett omdöme')"
        :label-score="$translate('ucr_label_score', 'Ditt betyg')"
        :label-name="$translate('ucr_label_name', 'Ditt förnamn')"
        :label-email="`
            <span>${$translate('ucr_label_email', 'Din e-postadress')}</span>
            <span>(${$translate('ucr_label_not_shown_publicly', 'Visas inte offentligt')})</span>
        `"
        :label-header="`
            <span>${$translate('ucr_label_header', 'Ge ditt omdöme en rubrik')}</span>
            <span>(${$translate('ucr_label_voluntary', 'Frivilligt')})</span>
        `"
        :label-body="`
            <span>${$translate('ucr_label_body', 'Berätta om din erfarenhet av ') + documentTitle }</span>
            <span>(${$translate('ucr_label_min_20_characters', 'Minst 20 tecken') })</span>
        `"
        :submit-button-text="$translate('ucr_form_submit_button', 'Skicka omdöme')"
        :post-success-msg="`
            <h2>${$translate('thanks', 'Tack')}!</h2>
            <p>${$translate('ucr_form_successful_post', 'Ditt omdöme kommer att publiceras så fort det har blivit granskat.')}</p>
        `"
        :email-error-msg="$translate('ucr_error_text_email', 'E-postadressen är ogiltig.')"
        :enable-header-input="true"
        :form-source-id="sourceId || 0"
    />
</template>

<script>
import { Ucr } from '@swegaming-ab/vue-components';
import { escapeHtml } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { Ucr },
    props: {
        document: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {
            sourceId: parseInt(process.env.UCR_SOURCE_ID),
            documentTitle: escapeHtml(this.document.data.title)
        };
    }
};
</script>
