<template>
    <div class="card-info">
        <div v-html="header" />
        <ul class="width-full list-border-bottom">
            <li
                v-for="(info, index) in cardInfo"
                :key="index"
                class="flex padding-y-3 flex-keep"
            >
                <span class="flex-1 text-bold">{{ info.header }}</span>
                <span class="flex-1 text-lighter" v-html="info.data" />
            </li>
        </ul>
        <span 
            v-if="$validateText(cardModel.complianceExample)" 
            class="compliance-example"
        >
            {{ cardModel.complianceExample }}
        </span>
    </div>
</template>

<script>
import { capitalize } from 'lodash';
export default {
    props: {
        cardModel: {
            type: Object,
            required: true
        },
        headerTag: {
            type: String,
            required: false,
            default: 'h2'
        }
    },
    computed: {
        header () {
            return `<${this.headerTag} class="card-info-header">${this.$translate('card_info_header', 'Kortfakta')}</${this.headerTag}>`;
        },
        cardInfo () {
            return [
                {
                    header: this.$translate('card_info_yearly_fee', 'Årsavgift'),
                    data: this.cardModel.yearlyCostHtml,
                    show: true
                },
                {
                    header: this.$translate('card_info_highest_credit', 'Högsta kredit'),
                    data: this.cardModel.getMaxCreditString(this.translate('bd_unlimited', 'Obegränsad')),
                    show: this.cardModel.isCredit
                },
                {
                    header: this.$translate('card_info_interest', 'Ränta'),
                    data: this.cardModel.interestString,
                    show: this.cardModel.isCredit
                },
                {
                    header: this.$translate('card_info_effective_interest', 'Effektiv ränta'),
                    data: this.cardModel.effectiveInterestString,
                    show: this.cardModel.isCredit
                },
                {
                    header: this.$translate('card_info_interest_free', 'Räntefritt'),
                    data: this.cardModel.getInterestFreeDaysString(this.translate('bd_days', '{days} dagar')),
                    show: this.cardModel.isCredit
                },
                {
                    header: this.$translate('card_info_card_type', 'Korttyp'),
                    data: capitalize(this.cardModel.paymentNetwork),
                    show: true
                },
                {
                    header: this.$translate('card_info_withdrawal_fee', 'Uttagsavgift'),
                    data: this.cardModel.getWithdrawalFeeString(
                        this.translate('bd_fee_percentage_and_min', '{percentage} (min {amount})'),
                        this.translate('bd_fee_percentage_and_fixed', '{percentage} + {amount}')
                    ),
                    show: true
                },
                {
                    header: this.$translate('card_info_currency_surcharge', 'Valutapåslag i utlandet'),
                    data: this.cardModel.getCurrencyExchangeFeeString(),
                    show: true
                },
                {
                    header: this.$translate('card_info_avi_fee', 'Aviavgift'),
                    data: this.cardModel.getAviFeeString(this.translate('bd_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)')),
                    show: this.cardModel.isCredit
                },
                {
                    header: this.$translate('card_info_reminder_fee', 'Påminnelseavgift'),
                    data: this.cardModel.reminderFeeString,
                    show: this.cardModel.isCredit
                },
                {
                    header: this.$translate('card_info_late_fee', 'Förseningsavgift'),
                    data: this.cardModel.latePaymentFeeString,
                    show: this.cardModel.isCredit
                },
                {
                    header: this.$translate('card_info_overdraft_fee', 'Övertrasseringsavgift'),
                    data: this.cardModel.overdraftFeeString,
                    show: this.cardModel.isCredit
                },
                {
                    header: 'Google pay',
                    data: this.$translate('yes', 'Ja'),
                    show: this.cardModel.hasGooglePay
                },
                {
                    header: 'Apple pay',
                    data: this.$translate('yes', 'Ja'),
                    show: this.cardModel.hasApplePay
                },
                {
                    header: 'Samsung pay',
                    data: this.$translate('yes', 'Ja'),
                    show: this.cardModel.hasSamsungPay
                }
            ].filter(info => info.show);
        }
    },
    methods: {
        translate (key, fallback) {
            return this.$translate(key, fallback);
        }
    },
};
</script>
