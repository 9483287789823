<template>
    <div>
        <div class="author-banner">
            <prismic-image
                class="author-image"
                :img="author.data.image"
                h="150"
                w="150"
            />
            <h1>{{ author.data.name }}</h1>
            <a 
                v-if="author.data.email"
                :href="`mailto:${author.data.email}`" 
                class="author-email-wrapper"
            >
                <i class="fas fa-envelope" />
                <span class="author-email">{{ author.data.email }}</span>
            </a>
        </div>
        <div class="author-preamble">
            <div class="divider-preamble-left" />
            <Section 
                v-if="$validateText(author.data.page_preamble)" 
                width="sm" 
                class="page-preamble-section"
            >
                <div class="page-preamble rich-text" v-html="$prismic.asHtml(author.data.page_preamble)" />
            </Section>
            <div class="divider-preamble-right" />
        </div>
        <Section width="sm" class="latest-posts-section">
            <div 
                v-if="$validateText(author.data.page_content)" 
                class="rich-text" 
                v-html="$prismic.asHtml(author.data.page_content)" 
            />

            <latest-articles
                v-show="authorPosts.length"
                :title="$translate('latest_posts', 'Senaste artiklarna')"
                :author-articles="authorPosts"
            />

            <latest-articles 
                v-show="postsReviewed.length"
                :title="$translate('posts_reviewed', 'Granskade artiklar')"
                :author-articles="postsReviewed"
            />
        </Section>
    </div>
</template>
<script>
import LatestArticles from '@/components/LatestArticles.vue';
export default {
    components: { LatestArticles },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            authorPosts: [],
            postsReviewed: []
        };
    },
    async fetch() {
        const predicatesPosts = [
            this.$prismic.predicates.at('document.type', 'post'),
            this.$prismic.predicates.at('my.post.author', this.author.id),
        ];
        const predicatesReviews = [
            this.$prismic.predicates.at('document.type', 'post'),
            this.$prismic.predicates.at('my.post.editor', this.author.id)
        ];
        this.authorPosts = (await this.$prismic.api.query(predicatesPosts,
            { 
                orderings: '[document.first_publication_date desc]',
                lang: process.env.PRISMIC_LANG
            }
        )).results;

        this.postsReviewed = (await this.$prismic.api.query(predicatesReviews,
            { 
                orderings: '[document.first_publication_date desc]',
                lang: process.env.PRISMIC_LANG
            }
        )).results;
    },
    fetchKey() {
        return `author-${this.author.id}`;
    },
    computed: {
        author() {
            return this.document;
        }
    }
};
</script>