<template>
    <nuxt-link 
        v-animate="{ threshold: .3, animation: 'up-slide' }"
        :to="$prismic.linkResolver(page)" 
        class="list-page"
    >
        <prismic-image 
            v-if="page.image.url"
            :img="page.image.thumbnail || page.image"
            w="560" 
            class="list-page-image"
        />
        <div class="list-page-body">
            <span v-if="page.title" class="body-title capitalize-first-letter">
                {{ page.title }}
            </span>
            <div class="body-footer">
                <span v-if="page.author" class="body-author">
                    {{ page.author.data.name }}
                </span>
                <div>
                    <i class="fas fa-calendar date-icon" />
                    <span class="date">{{ $format.date(page.date) }}</span>
                </div>
                <read-time :data="page.body" />
            </div>
        </div>
    </nuxt-link>
</template>

<script>
import ReadTime from '@/components/ReadTime.vue';
export default {
    components: { ReadTime },
    props: {
        page: {
            type: Object,
            required: true
        }
    }
};
</script>