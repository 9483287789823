export default {
    computed: {
        rowHeaders(){
            return Object.entries(this.data.primary)
                .filter(([key, value]) => key.startsWith('row') && value)
                .map(([, value]) => value);
        },
        rowsContent() {
            return this.rowHeaders.map((header, index) => {
                return {
                    header: header,
                    data: this.rowData(index)
                };
            });
        },
    },
    methods: {
        rowText(rowIndex, cardIndex) {
            return this.data.items[cardIndex][`row_${rowIndex + 1}_data`];
        },
        rowCheckmark(rowIndex, cardIndex) {
            return this.data.items[cardIndex][`row_${rowIndex + 1}_check_mark`];
        },
        rowData(rowIndex){
            return this.data.items.map((_item, cardIndex) => {
                return {
                    text: this.rowText(rowIndex, cardIndex), 
                    checkmark: this.rowCheckmark(rowIndex, cardIndex)
                };
            });
        }
    }
};