<template>
    <Section 
        width="md"
        class="table-section" 
    >
        <div 
            v-if="$validateText(data.primary.title)" 
            class="rich-text" 
            v-html="$prismic.asHtml(data.primary.title)" 
        />
        <div 
            v-if="$validateText(data.primary.p)" 
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.p)" 
        />
        <div class="table-card margin-top-4">
            <table-titles-row 
                v-if="data.primary.align_titles === 'row'"
                :table-titles="tableTitles"
                :table-values="tableValues"
            />
            <table-titles-column
                v-else
                :table-titles="tableTitles"
                :table-values="tableValues"
            />
        </div>
        <div 
            v-if="$validateText(data.primary.footer_text)"
            class="rich-text footer-text"
            v-html="$prismic.asHtml(data.primary.footer_text)"
        />
    </Section>
</template>
<script>
import TableTitlesRow from './TableTitlesRow.vue';
import TableTitlesColumn from './TableTitlesColumn.vue';
export default {
    components: {
        TableTitlesRow,
        TableTitlesColumn
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        tableTitles() {
            return Object.entries(this.data.primary)
                .filter(([key, value]) => key.startsWith('title_') && value)
                .map(([, value]) => value);
        },
        tableValues() {
            return this.data.items
                .map(items => Object.values(items).slice(0, this.tableTitles.length));
        }
    }
};
</script>