<template>
    <Section class="banner banner-expanders bg-slgray" width="md">
        <div class="container">
            <div class="content">
                <div class="banner-preamble">
                    <h1 
                        class="section-header blue rich-text" 
                        :class="{'remove-margin': !$validateText(data.primary.teaser)}"
                    >
                        {{ $prismic.asText(data.primary.h1) }}
                    </h1>
                    <div 
                        v-if="$validateText(data.primary.teaser)" 
                        class="rich-text width-full" 
                        v-html="$prismic.asHtml(data.primary.teaser)"
                    />
                </div>
                <div class="expanders">
                    <expander 
                        v-if="data.primary.show_toplist"
                        key="expander-toplist"
                        :title="toplistHeader" 
                    > 
                        <toplist :slices="slices" :amount="data.primary.toplist_amount" />
                    </expander>
                    
                    <expander 
                        v-for="(expander, index) in expanders"
                        :key="`expander-${index}`"
                        :title="expander.expander_header" 
                    > 
                        <div class="rich-text" v-html="$prismic.asHtml(expander.expander_content)" />
                    </expander>

                    <expander 
                        v-if="data.primary.show_table_of_contents"
                        key="expander-page-content"
                        :title="$translate('table_of_contents', 'Sidans innehåll')" 
                    >
                        <ul class="toc-list">
                            <li 
                                v-for="link in pageContentLinks"
                                :key="link.id"
                            >
                                <a 
                                    v-scroll-to="{el: `#${$helpers.formatId(link.id)}`, offset: -60}"
                                    :href="`#${$helpers.formatId(link.id)}`"
                                    class="toc-link"
                                >
                                    {{ link.primary.link_text }}
                                </a>
                            </li>
                        </ul>
                    </expander>
                </div>
                <div v-if="data.primary.card.id" class="card-container">
                    <transition name="slide-left" appear>
                        <CardBanner :id="data.primary.card.id" :data="data" />
                    </transition>
                </div>
            </div>
        </div>
    </Section>
</template>

<script>
import CardBanner from '@/components/cards/CardBanner.vue';
import Expander from '@/components/Expander.vue';
import Toplist from '@/components/Toplist.vue';
export default {
    components: { 
        CardBanner,
        Expander, 
        Toplist 
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        slices: {
            type: Array,
            required: true
        }
    },
    computed: {
        expanders() {
            return this.data.items
                .filter(item => item.expander_header);
        },
        pageContentLinks() {
            return this.slices
                .filter(slice => slice.slice_type === 'table_of_contents_link');
        },
        toplistHeader() {
            if (this.$validateText(this.data.primary.toplist_header)) {
                return this.data.primary.toplist_header;
            }
            return this.$translate('toplist', 'Topplista');
        }
    }
};
</script>