<template>
    <Section class="list-lenders" width="lg">
        <div class="cards-header">
            <div 
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.title)"
            />
            <div 
                class="rich-text"
                v-html="$prismic.asHtml(data.primary.teaser)"
            />
        </div>
        <div class="lenders">
            <Lender 
                v-for="lender in lenders"
                :key="lender.id"
                :lender="lender"
            />
        </div>
    </Section>
</template>

<script>
import Lender from '@/components/ListItemLender.vue';
export default {
    components: {
        Lender,
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    computed: {
        lenders() {
            return this.data.items
                .map(iterator => iterator.lender)
                .filter(lender => lender.id);
        }
    }
};
</script>

<style lang="scss" scoped>
    section.list-lenders {
        .cards-header {
            width: 100%;
            margin-bottom: 1rem;
        }
        .lenders {
            display: grid;
            width: 100%;
            gap: 40px;
            grid-template-columns: repeat( 1, 1fr );
        }
    }
</style>
