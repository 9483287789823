<template>
    <div class="wrapper-credit-card bg-slgray">
        <transition name="dropdown">
            <Dropdown v-if="showCtaBanner" :document="document" />
        </transition>
        
        <Overview :card-model="cardModel" :document="document" />

        <QuickLinks />

        <Description :card-model="cardModel" :document="document" />

        <Info :card-model="cardModel" />

        <Bonus :card-model="cardModel" :document="document" />

        <Section
            id="details"
            class="rich-text-slice bg-white"
            width="sm"
        >
            <div
                class="rich-text label-icons"
                v-html="$prismic.asHtml(document.data.content)"
            />
        </Section>

        <OtherCards v-if="otherCardIds.length" :document="document" :other-card-ids="otherCardIds" />

        <Faq v-if="showFaq" :document="document" :faqs="faqs" />

        <Section
            id="write-review"
            width="md"
            class="bg-blue"
        >
            <h2 class="section-header white width-full text-center">
                {{ $translate('ucr_what_does_our_users_think_of', 'Vad tycker våra användare om {card}?')
                    .replace('{card}', document.data.title) 
                }}
            </h2>
            <Button
                v-scroll-to="'#form'"
                :text="$translate('ucr_write_a_review', 'Skriv ett omdöme')"
                background="white"
                size="md"
            />
        </Section>

        <UserRatings :document="document" />

        <Contact :card-model="cardModel" />

        <script type="application/ld+json" v-html="jsonld" />
    </div>
</template>
<script>
import UserRatings from './UserRatings.vue';
import Overview from './Overview.vue';
import QuickLinks from './QuickLinks.vue';
import Description from './Description.vue';
import Bonus from './Bonus.vue';
import Contact from './Contact.vue';
import Dropdown from './Dropdown.vue';
import OtherCards from './OtherCards.vue';
import Faq from './Faq.vue';
import Info from './Info.vue';

export default {
    components: {
        UserRatings,
        Overview,
        QuickLinks,
        Description,
        Bonus,
        Contact,
        Dropdown,
        OtherCards,
        Faq,
        Info
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            showCtaBanner: false
        };
    },
    computed: {
        faqs() {
            return this.document.data.faq.filter(iterator => iterator.question && iterator.question.length);
        },
        showFaq() {
            return this.faqs.length > 0;
        },
        otherCardIds() {
            const otherCardsInDocument = this.document.data.other_cards.filter(this.$helpers.filterEmptyRelations);
            if (otherCardsInDocument.length) {
           
                let ids = [];
                otherCardsInDocument.forEach((iterator) => {
                    ids.push(iterator.card.id);
                });
                return ids;
            }

            return this.$store.state.cards[this.document.id].otherCardIds;
            
        },
        cardModel() {
            return new this.$models.CreditCard(this.document, this.$store);
        },
        jsonld() {

            if (!(this.cardModel._ratings && 
                    this.cardModel._ratings.data && 
                    this.cardModel._ratings.data.length)) {
                return '';
            }

            let jsonld = {
                '@context': 'https://schema.org',
                '@type': 'Product',
                'name': this.document.data.title,
                'dateModified': this.document.last_publication_date.split('T')[0],
            };

            if (this.document.data.image && this.document.data.image.url) {
                jsonld.image = this.document.data.image;
            }

            if (this.cardModel) {
                jsonld.aggregateRating = {
                    '@type': 'AggregateRating',
                    'ratingValue': this.cardModel._ratings.average_score,
                    'ratingCount': this.cardModel._ratings.count,
                    'reviewCount': this.cardModel._ratings.count,
                    'worstRating': '1',
                    'bestRating': '5'
                };
            }

            return jsonld;
        }
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.scroll);
    },
    beforeMount() {
        window.addEventListener('scroll', this.scroll);
    },
    methods: {
        scroll: function(){
            if (window.scrollY >= 1000){
                this.showCtaBanner = true;
            }
            else {
                this.showCtaBanner = false;
            }
        }
    }
};
</script>
