<template>
    <Section width="md" class="big-list">
        <div 
            v-if="$validateText(data.primary.text)"
            class="rich-text"
            :class="{'title-border-bottom': !data.primary.show_toplist}"
            v-html="$prismic.asHtml(data.primary.text)"
        />
        <top-list 
            v-if="data.primary.show_toplist"
            :items="items"
            :data-id="formatId(data.id)"
        />
        <big-list-item 
            v-for="(item, index) in items"
            :id="`bigListItem${formatId(data.id) + index}`"
            :key="item.card.id"
            :item="item"
            :data-type="data.primary.data_type"
        />
    </Section>
</template>

<script>
import { pick } from 'lodash';
import TopList from '@/components/big-list/TopList.vue';
import BigListItem from '@/components/big-list/BigListItem.vue';
export default {
    components: {
        TopList,
        BigListItem
    }, 
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        items() {
            return this.data.items.filter(item => item.card.id)
                .map((item, index) => {
                    const card = this.$store.state.cards[item.card.id];
                    return {
                        card: card,
                        h3: this.$validateText(item.h3) ?`${index + 1}. ${item.h3}` : `${index + 1}. ${card.data.title}`,
                        toplistText: item.toplist_text,
                        toplistTitle: this.$validateText(item.toplist_text) ? `${card.data.title}:` : card.data.title,
                        teaser: item.teaser,
                        prosList: this.getProsList(card, item),
                        model: new this.$models.CreditCard(card),
                        tableData: Object.values(this.getTableData(card))
                    };
                });
        }
    },
    methods: {
        formatId(id) {
            return id.replace('$', '-');
        },
        getProsList(card, item) {
            if (this.$validateText(item.pros)) {
                return item.pros.map(pro => pro.text);
            } 
            return card.data.pros_list.map(pro => pro.pro);
        },
        getTableData(card) {
            const model = new this.$models.CreditCard(card);
            return this.tableData(
                [
                    'yearlyCost',
                    'interestString',
                    'effectiveInterestString',
                    'maxCredit',
                    'interestFreeDays'
                ],
                model
            );
        },
        tableData(keys, model) {
            const tableData = {
                interestString: {
                    title: this.$translate('card_info_interest', 'Ränta'),
                    value: model.interestString
                },
                effectiveInterestString: {
                    title: this.$translate('card_info_effective_interest', 'Effektiv ränta'),
                    value: model.effectiveInterestString
                },
                yearlyCost: {
                    title: this.$translate('card_info_yearly_fee', 'Årsavgift'),
                    value: model.yearlyCostHtml
                },
                maxCredit: {
                    title: this.$translate('card_info_highest_credit', 'Högsta kredit'),
                    value: model.getMaxCreditString(this.$translate('bd_unlimited', 'Obegränsad'))
                },
                interestFreeDays: {
                    title: this.$translate('card_info_interest_free', 'Räntefritt'),
                    value: model.getInterestFreeDaysString(this.$translate('bd_days', '{days} dagar'))
                }
            };
            return pick(tableData, keys);
        }
    }
};
</script>