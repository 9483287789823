<template>
    <Section 
        v-if="data.primary.image.url"
        class="centered-image-slice"
        :class="{'remove-padding-y': data.primary.remove_padding}"
        :width="sectionWidth" 
    >
        <div class="wrapper" :class="{'content-reverse': data.primary.text_position === 'below_image'}">
            <div 
                v-if="$validateText(data.primary.text)"
                class="rich-text" 
                v-html="$prismic.asHtml(data.primary.text)"
            />
            <prismic-image 
                class="image"
                :img="data.primary.image" 
                :w="imageWidth"
            />
        </div>
    </Section>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        sectionWidth() {
            return this.data.primary.width ?? 'sm';
        },
        imageWidth() {
            if (this.sectionWidth === 'md') {
                return '1000';
            }
            else if (this.sectionWidth === 'lg') {
                return '1200';
            }
            return '800';
        }
    }
};
</script>
<style lang="scss" scoped>
    section.centered-image-slice {
        .image {
            width: 100%;
        }
        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            &.content-reverse {
                flex-direction: column-reverse;
            }
        }
    }
    section.centered-image-slice.remove-padding-y {
        padding-top: 0;
        padding-bottom: 0;
    }
</style>