<template>
    <Section class="list-cards" width="md" :class="'bg-' + data.primary.background">
        <h2
            v-if="$helpers.filterEmptyElements(data.primary.h2)"
            class="section-header green m-center"
        >
            {{ $prismic.asText(data.primary.h2) }}
        </h2>
        <div
            v-if="$helpers.filterEmptyElements(data.primary.teaser)"
            class="section-header width-full"
            v-html="$prismic.asHtml(data.primary.teaser)"
        />

        <div class="sorting">
            <button
                class="btn btn-sm"
                :class=" activeSort === 'recommended' ? 'btn-green' : 'btn-white'"
                @click="activeSort = 'recommended'"
            >
                {{ $translate('sort_recommended', 'Rekommenderade först') }}
            </button>
            <button
                class="btn btn-sm"
                :class=" activeSort === 'alphabetical' ? 'btn-green' : 'btn-white'"
                @click="activeSort = 'alphabetical'"
            >
                {{ $translate('sort_alphabetical', 'Alfabetiskt') }}
            </button>
        </div>
        <div class="container">
            <Card
                v-for="(card, index) in sortedCards"
                :key="card.id"
                :card="card"
                placement="list"
                :position="index + 1"
            />
        </div>
        <trust-us v-if="!data.primary.disable_trust_us" />
    </Section>
</template>

<script>
import Card from '@/components/cards/Card.vue';
import TrustUs from '@/components/TrustUs.vue';
import { partition, orderBy } from 'lodash';
export default {
    components: { Card, TrustUs },
    props: {
        data: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            activeSort: 'recommended',
        };
    },
    computed: {
        sortedCards() {
            if (this.activeSort === 'recommended') {
                const [partnerCards, otherCards] = partition(
                    this.$store.state.cards,
                    'data.redirect_enabled'
                );
                return [
                    ...this.$getOrderedList(partnerCards),
                    ...this.$getOrderedList(otherCards),
                ];
            }
            return orderBy(this.$store.state.cards, 'data.title', 'asc');
        },
    },
};
</script>

<style lang="scss" scoped>
.container {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(1, 1fr);
}

.sorting {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    row-gap: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
}
</style>
