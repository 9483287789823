<template>
    <div class="offer bg-green text-center white text-bold width-full">
        <a
            v-if="link.data.redirect_enabled"
            :href="$helpers.getRedirectUrl(link, placement, position)"
            target="_blank"
            class="link white"      
        >
            {{ offerText }}
        </a>
        <span v-else>
            {{ offerText }}
        </span>
    </div>
</template>

<script>
export default {
    props: {
        offerText: {
            type: String,
            required: true
        },
        link: {
            type: Object,
            required: true,
        },
        placement: {
            type: String,
            required: false,
            default: null
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
    .offer {
        padding: 10px 15px;
        border-radius: 10px 10px 0 0;
    }
    .link {
        text-decoration: none;
    }
    .link:hover {
        text-decoration: underline;
    }
</style>