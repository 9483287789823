<template>
    <div>
        <Slices :slices="document.data.body" :document=" document" />
        <AuthorBox v-if="showAuthorBox" :document="document" />
    </div>
</template>

<script>
import AuthorBox from '@/components/AuthorBox.vue';
import Slices from '@/components/slices/Slices.vue';

export default {
    components: { 
        AuthorBox, 
        Slices 
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        showAuthorBox() {
            return this.document.data.author &&
                this.document.data.author.id;
        },
    },
};
</script>