<template>
    <Section
        class="list-cards"
        width="md"
        :class="'bg-' + data.primary.background"
    >
        <div class="card-category width-full header-container">
            <h2 class="green margin-bottom-4">{{ $prismic.asText(data.primary.h2) }}</h2>
            <div 
                v-if="!data.primary.card_info_box" 
                class="card-info-teaser margin-bottom-2"
                v-html="$prismic.asHtml(data.primary.teaser)" 
            />
        </div>
        <div v-if="data.primary.card_info_box" class="card-info-container card-category rich-text width-full margin-bottom-4 margin-top-4">
            <div 
                v-if="$helpers.filterEmptyElements(data.primary.teaser)" 
                class="card-info-teaser" 
                v-html="$prismic.asHtml(data.primary.teaser)" 
            />
            <ul class="card-info-list margin-0">
                <li 
                    v-for="(card, index) in cards" 
                    :key="index"
                    class="text-bold card-info-box"
                >
                    <div>
                        <nuxt-link :to="$prismic.linkResolver(card)" class="text-bold">
                            <span>{{ card.title }}</span>
                        </nuxt-link>
                        <span class="card-info-category">- {{ card.category }}</span>
                    </div>
                </li>
            </ul>
        </div>
        <CardCategory
            v-for="(card, index) in cards"
            :id="card.id"
            :key="index"
            :category="card.category"
            :category-text="card.categoryText"
            placement="list-category"
            :position="index + 1"
        />
    </Section>
</template>

<script>
import CardCategory from '@/components/cards/CardCategory.vue';
export default {
    components: { CardCategory },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        cards() {
            return this.data.items
                .filter(category => category.card.id)
                .map(category => {
                    return {
                        id: category.card.id,
                        card: this.$store.state.cards[category.card.id], 
                        title: this.$store.state.cards[category.card.id].data.title,
                        category: category.card_category,
                        categoryText: category.card_category_text
                    };
                })
                .filter(category => ! category.card.data.lists_disabled);
        }
    }
};
</script>
<style lang="scss" scoped>
    .card-info-container {
        padding: 1rem 2rem;
        background: hsla(0,0%,96.9%,.7);
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
        border-radius: 0.25rem;
        font-size: 14px;
    }
    .card-info-box {
        margin: 12px 0;
    }
    .card-info-category {
        text-transform: lowercase;
    }
    @media only screen and (max-width: 600px) {
        .section.list-cards div.header-container {
            text-align: center;
        }
        .card-info-container {
            padding: 1rem;
        }
    }
</style>
