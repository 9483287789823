<template>
    <Section
        class="faq"
        width="sm"
        :class="'bg-' + data.primary.background"
    >
        <div class="width-full text-center flex">
            <h2 class="section-header blue">{{ $prismic.asText(data.primary.h2) }}</h2>
            <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="section-header" v-html="$prismic.asHtml(data.primary.teaser)" />
        </div>

        <div class="width-full" itemscope itemtype="https://schema.org/FAQPage">
            <QuestionAndAnswer
                v-for="(iterator, index) in data.items"
                :key="index"
                :question="iterator.question"
                :answer="$prismic.asHtml(iterator.answer)"
            />
        </div>
    </Section>
</template>

<script>
import { QuestionAndAnswer } from '@swegaming-ab/vue-components';
export default {
    components: { QuestionAndAnswer },
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>
