<template>
    <tr>
        <th>{{ fee.title }}</th>
        <td v-for="(cardFee, i) in fee.values" :key="i"
            :class="{ highlight: checkLowest(fee.key, cards[i]) }"
        > 
            {{ cardFee }}
        </td> 
    </tr>
</template>

<script>
export default {
    props: {
        fee: {
            type: Object,
            required: true
        },
        cards: {
            type: Array,
            required: true
        },
        lowest: {
            type: Object,
            required: true
        },
        checkLowest: {
            type: Function,
            required: true
        }
    },
};
</script>