<template>
    <div class="banner banner-card flex flex-stretch width-full">
        <div class="container flex">
            <div class="container-inner flex flex-x-start">
                <h1 class="blue section-header">{{ document.data.title }}</h1>
                <ul class="font width-full">
                    <li v-for="(iterator, index) in document.data.pros_list" :key="index">
                        {{ iterator.pro }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="container flex">
            <transition name="slide-left" appear>
                <div class="container-inner">
                    <div class="card card-banner bxs border flex br-3 bg-white padding-8">
                        <PrismicImage
                            :img="document.data.image"
                            w="200"
                            h="126"
                        />

                        <div class="rating margin-top-2 margin-bottom-4 width-full text-center">
                            <RatingStars
                                :average-score="$helpers.getCardStarsCount(document)"
                                star-size="26"
                            />
                        </div>

                        <CardShortInfo :card-model="cardModel" />
                        <ButtonTarget
                            :link="document"
                            placement="banner"
                            :text="$translate('button_apply', 'Till ansökan')"
                            size="md"
                            background="orange"
                            icon="fas fa-lock"
                            class="margin-top-8"
                        />
                    </div>
                    <div 
                        v-if="$validateText(cardModel.complianceExample)" 
                        class="text-compliance padding-x-1"
                    >
                        {{ cardModel.complianceExample }}
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import CardShortInfo from '@/components/CardShortInfo.vue';


export default {
    components: {
        CardShortInfo
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        cardModel: {
            type: Object,
            required: true
        }
    }
};
</script>