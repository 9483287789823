<template>
    <Section width="lg" class="highlighted-documents">
        <div 
            v-if="data.primary.h2 || $validateText(data.primary.teaser)" 
            class="highlighted-documents-header blue"
        >
            <h2 v-if="data.primary.h2">
                {{ data.primary.h2 }}
            </h2>
            <div 
                v-if="$validateText(data.primary.teaser)"
                class="rich-text" 
                v-html="$prismic.asHtml(data.primary.teaser)"
            />
        </div>
        <div class="grid">
            <div class="highlighted-container">
                <selected-document-item 
                    v-for="(document, index) in highlightedDocuments.slice(0, 1)" 
                    :key="index" 
                    :document="document"
                    class="highlighted"
                />
            </div>
            <div class="container">
                <selected-document-item 
                    v-for="(document, index) in highlightedDocuments.slice(1, 4)" 
                    :key="index" 
                    :document="document"
                    class="default"
                />
            </div>
        </div>
    </Section>
</template>
<script>
import SelectedDocumentItem from '@/components/SelectedDocumentItem.vue';
export default {
    components: { SelectedDocumentItem },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            highlightedDocuments: [],
        };
    },
    async fetch() {
        this.highlightedDocuments = (await this.$prismic.api.getByIDs(this.documentIds,
            { 
                lang: process.env.PRISMIC_LANG, 
                pageSize: 4
            }
        )).results;
    },
    fetchKey() {
        return `highlighted-documents-${this.data.id}`;
    },
    computed: {
        documentIds() {
            return this.data.items.filter(item => item.document.id)
                .map(item => item.document.id);
        }
    }
};
</script>