<template>
    <div class="list-item">        
        <prismic-image 
            :img="lender.data.logo"
            w="150"
            h="auto" 
        />
        <table class="table-data">
            <thead>
                <tr>
                    <th>{{ $translate('card_info_amount', 'Belopp') }}</th>
                    <td>{{ model.amountString }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('card_info_interest', 'Ränta') }}</th>
                    <td>{{ model.interestString }}</td>
                </tr>
                <tr>
                    <th>{{ $translate('card_info_duration', 'Löptid') }}</th>
                    <td>{{ model.getDurationString() }}</td>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th>{{ $translate('card_info_credit_check', 'Kreditupplysning') }}</th>
                    <td class="capitalize">{{ model.creditCheck }}</td>
                </tr>
                <tr v-if="model.isBroker">
                    <th>{{ $translate('card_info_connected_banks', 'Anslutna banker') }} </th>
                    <td>{{ model.connectedBanks }}</td>
                </tr>
                <tr v-else>
                    <th>{{ $translate('card_info_without_uc', 'Utan UC') }} </th>
                    <td v-if="! model.takesUC"><i class="far fa-check-circle icon-success" /></td>
                    <td v-else><i class="far fa-times-circle icon-fail" /></td>
                </tr>
                <tr>
                    <th>{{ $translate('card_info_payment_remarks', 'Bet. anmärkning') }} </th>
                    <td v-if="model.acceptsPaymentRemarks"><i class="far fa-check-circle icon-success" /></td>
                    <td v-else><i class="far fa-times-circle icon-fail" /></td>
                </tr>
            </tbody>
        </table>
        <ButtonTarget
            class="button"
            :link="lender"
            :text="$translate('button_apply', 'Till ansökan')"
            background="orange"
            size="md"
        />
    </div>   
</template>
<script>
export default {
    props: {
        lender: {
            type: Object,
            required: true
        }
    },
    computed: {
        model() {
            return new this.$models.Loan(this.lender);
        }
    }
};
</script>
<style lang="scss" scoped>

.capitalize {
    text-transform: capitalize;
}

.icon-success {
    color: green;
}
.icon-fail {
    color: red;
}

.list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: 0 0 0.5rem rgba(0,0,0,.1);
    border: .0625rem solid rgba(33, 50, 91, .2);
    transition: .3s ease-in-out;

    .button {
        width: 100%;
        text-align: center;
    }

    @media screen and (min-width: 900px) {
        flex-direction: row;
        padding: 2rem;
        
        .button {
            text-align: start;
            width: unset;
        }
    }

    .table-data {
        width: 100%;
        margin: 2rem 0;
        @media screen and (min-width: 900px) {
            margin: 0;
            width: 60%;
            gap: 2rem;
            display: flex;
            justify-content: space-between;
        }
        thead, tbody {
            width: 100%;
            tr {
                border-bottom: .0625rem solid rgba(33, 50, 91, .1);
                display: flex;
                justify-content: space-between;
                
                td, th {
                    padding: 15px 5px;
                }
                td {
                    font-weight: bold;
                    text-align: right;
                }
            }
        }
    }
}
</style>