export default {
    computed: {
        fees() {
            return [
                {
                    title: this.$translate('card_info_withdrawal_fee', 'Uttagsavgift'),
                    values: this.cards.map(card => {
                        return card.model.getWithdrawalFeeString(
                            this.$translate('bd_fee_percentage_and_min', '{percentage} (min {amount})'),
                            this.$translate('bd_fee_percentage_and_fixed', '{percentage} + {amount}'));
                    }),
                },
                {
                    title: this.$translate('card_info_currency_surcharge', 'Valutapåslag i utlandet'),
                    values: this.cards.map(card => {
                        return card.model.getCurrencyExchangeFeeString();
                    }),
                    key: 'currencyExchangeFeePercentage'
                },
                {
                    title: this.$translate('card_info_avi_fee', 'Aviavgift'),
                    values: this.cards.map(card => {
                        return card.model.getAviFeeString(this.$translate('bd_avi_fee', '{paperInvoiceFee} ({eInvoiceFee} e-faktura)'));
                    }),
                    key: 'aviFeeEInvoice'
                },
                {
                    title: this.$translate('card_info_reminder_fee', 'Påminnelseavgift'),
                    values: this.cards.map(card => {
                        return card.model.reminderFeeString;
                    }),
                    key: 'reminderFee'
                },
                {
                    title: this.$translate('card_info_late_fee', 'Förseningsavgift'),
                    values: this.cards.map(card => {
                        return card.model.latePaymentFeeString;
                    }),
                    key: 'latePaymentFee'
                },
                {
                    title: this.$translate('compare_default_interest', 'Dröjsmålsränta'),
                    values: this.cards.map(card => {
                        return card.model.penaltyInterestString;
                    }),
                    key: 'penaltyInterest'
                },
                {
                    title: this.$translate('card_info_overdraft_fee', 'Övertrasseringsavgift'),
                    values: this.cards.map(card => {
                        return card.model.overdraftFeeString;
                    }),
                    key: 'overdraftFee'
                }
            ];
        }
    },
};