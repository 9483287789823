<template>
    <Section 
        v-if="allPagesByTag.length"
        :width="sliceWidth"
        class="pages-by-tag"
    >
        <div
            v-if="$validateText(data.primary.text)"
            class="rich-text"
            v-html="$prismic.asHtml(data.primary.text)"
        />
        
        <div class="page-card-container">
            <page-card 
                v-for="(page, index) in pages" 
                :key="index"
                :page="page"
            />
        </div>

        <Button
            v-if="!showAllPages && allPagesByTag.length > 3" 
            :text="$translate('show_more', 'Visa mer')"
            icon="fas fa-chevron-down"
            background="blue"
            size="md"
            class="page-button"
            @click="showAllPages = true"
        />
    </Section>
</template>
<script>
import { queryAll, replace } from '@swegaming-ab/nuxtjs-helpers';
import PageCard from '@/components/slices/pages-by-tag/PageCard.vue';
export default {
    components: { PageCard },
    props: {
        data: {
            type: Object,
            required: true
        },
        documentId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            allPagesByTag: [],
            showAllPages: false,
        };
    },
    async fetch () {
        const predicatesPages = [
            this.$prismic.predicates.at('document.type', 'page'),
            this.$prismic.predicates.at('document.tags', [this.pageTag]),
            this.$prismic.predicates.not('document.id', this.documentId),
        ];
        this.allPagesByTag = (await queryAll(this.$prismic.api,
            predicatesPages,
            { 
                orderings: '[document.last_publication_date desc]',
                lang: process.env.PRISMIC_LANG
            }
        ))
            .filter(page => page.id)
            .map(page => {
                return {
                    id: page.id,
                    title: page.data.title ? replace(page.data.title) : null,
                    image: page.data.image.url ? page.data.image : null,
                    date: page.last_publication_date,
                };
            });
    },
    computed: {
        pageTag() {
            return this.data.primary.page_tag;
        },
        pages() {
            return this.showAllPages ? this.allPagesByTag : this.allPagesByTag.slice(0, 3);
        },
        sliceWidth() {
            return this.data.primary.slice_width ?? 'sm';
        }
    }
};
</script>