<template>
    <table class="table">
        <thead>
            <tr class="table-header">
                <th v-for="(title, index) in tableTitles" :key="index">
                    {{ title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(values, index) in tableValues" :key="index">
                <td 
                    v-for="(value, valueIndex) in values" 
                    :key="valueIndex" 
                    class="rich-text no-margin"
                    v-html="$prismic.asHtml(value)"
                />
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        tableTitles: {
            type: Array,
            required: true
        },
        tableValues: {
            type: Array,
            required: true
        }
    }
};
</script>