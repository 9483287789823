<template>
    <div>
        <div class="rich-text">
            <ol>
                <li v-for="card in listSliceItems" :key="card.id">
                    <nuxt-link :to="$prismic.linkResolver(card)" class="toplist-item-link">
                        {{ card.data.title }}
                    </nuxt-link>
                </li>
            </ol>
        </div>
        <a
            v-scroll-to="{
                el: '.list-cards',
                offset: 0
            }"
            class="btn btn-md btn-green scroll-to-btn"
            href="#"
        >
            {{ $translate('button_show_top_list', 'Visa topplistan') }}
        </a>
    </div>
</template>

<script>
export default {
    props: {
        slices: {
            type: Array,
            required: true
        },
        amount: {
            type: Number,
            required: false,
            default: 5
        }
    },
    computed: {
        listSlice() {
            return this.slices.find(slice => {
                return slice.slice_type === 'list__best_per_category' ||
                slice.slice_type === 'list__card_category' ||
                slice.slice_type === 'list__cards' ||
                slice.slice_type === 'list__all_cards'; 
            });
        },
        listSliceItems() {
            if (! this.listSlice) {
                return null;
            }
            const hasList = Boolean(this.listSlice.primary?.predefined_list?.id);
            let cards;

            if (hasList) {
                cards = this.$store.state.lists[this.listSlice.primary.predefined_list.id]?.data.cards;
            }
            else if (this.listSlice.slice_type === 'list__all_cards') {
                cards = this.allCardsSorted;
            }
            else {
                cards = this.listSlice.items
                    .filter(item => item.card.id)
                    .map(item => {
                        return this.$store.state.cards[item.card.id];
                    });
            
            }
            return cards?.slice(0, this.amount);
        },
        allCardsSorted() {
            const sortable = Object.values(this.$store.state.cards);
            
            return sortable.sort(function(a, b) {
                if (a.data.title < b.data.title) {
                    return -1;
                }
                if (a.data.title > b.data.title) {
                    return 1;
                }
                return 0;
            });
        }
    },
};
</script>

<style lang="scss" scoped>
    .scroll-to-btn {
        display: inline-block;
    }
    .rich-text {
        a {
            font-weight: bold;
        }
    }
</style>