<template>
    <div id="bonus" class="flex boxes">
        <div class="box bg-green">
            <div class="box-inner white">
                <h2 class="width-full">{{ $translate('cr_bonus_and_benefit', 'Bonus & förmåner') }}</h2>
                <ul class="list-border-bottom">
                    <li 
                        v-for="(benefit, index) in benefits"
                        :key="index"
                        class="flex padding-y-2 flex-keep"
                    > 
                        <span class="flex-1">{{ benefit.title }}:</span>
                        <span class="flex-1 text-lighter">{{ benefit.value }}</span>
                    </li>
                </ul>

                <div class="obs width-full border-top margin-top-10 padding-top-4">{{ $translate('obs_bonus_and_benefit', 'Läs mer om bonus nedan.') }}</div>
            </div>
        </div>

        <div class="box bg-slgray">
            <div class="box-inner flex flex-y-start">
                <h2 class="margin-left-4 m-margin-left-2 width-full">{{ $translate('cr_insurance', 'Försäkringar') }}</h2>
                
                <ul class="list-checkmark insurance text-lighter flex-1 padding-4">
                    <li class="no-style text-bold green">{{ $translate('cr_exist_true', 'Finns') }}</li>
                    <li 
                        v-for="(insurance, index) in isInsured"
                        :key="index" 
                    >
                        {{ insurance.title }}
                    </li>
                </ul>

                <ul class="list-cross insurance text-lighter flex-1 padding-4">
                    <li class="no-style text-bold red">{{ $translate('cr_exist_false', 'Finns inte') }}</li>
                    <li 
                        v-for="(insurance, index) in isNotInsured"
                        :key="index" 
                    >
                        {{ insurance.title }}
                    </li>
                </ul>
            </div>
            <div class="obs width-full border-top margin-top-10 padding-top-4">{{ $translate('obs_insurance', 'Observera att vissa försäkringar ibland inte ingår automatiskt. Läs mer nedan. ') }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        cardModel: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasLoungeAccess () {
            return this.cardModel.hasLoungeAccess ?
                this.$translate('yes', 'Ja') :
                this.$translate('no', 'Nej');
        },
        hasConciergeService () {
            return this.cardModel.hasConciergeService ?
                this.$translate('yes', 'Ja') :
                this.$translate('no', 'Nej');
        },
        hasBonusProgram () {
            return this.cardModel.hasBonusProgram ?
                this.$translate('yes', 'Ja') :
                this.$translate('no', 'Nej');
        },
        hasCashback () {
            return this.cardModel.hasCashback ?
                this.$translate('yes', 'Ja') :
                this.$translate('no', 'Nej');
        },
        benefits () {
            return [
                {
                    title: this.$translate('cr_bonus', 'Bonus'),
                    value: this.hasBonusProgram
                },
                {
                    title: this.$translate('cr_cincierge_service', 'Concierge Service'),
                    value: this.hasConciergeService
                },
                {
                    title: this.$translate('cr_access_to_lounge', 'Tillgång till lounger'),
                    value: this.hasLoungeAccess
                },
                {
                    title: this.$translate('cr_gives_cashback', 'Ger cashback'),
                    value: this.hasCashback
                },
            ];
        },
        insurances () {
            return [
                {
                    exist: this.cardModel.hasPurchaseProtection,
                    title: this.$translate('insurance_purchase_protection', 'Köpskydd')
                },
                {
                    exist: this.cardModel.hasDeliveryInsurance,
                    title: this.$translate('insurance_delivery', 'Leveransförsäkring')
                },
                {
                    exist: this.cardModel.hasTravelInsurance,
                    title: this.$translate('insurance_travel', 'Reseförsäkring')
                },
                {
                    exist: this.cardModel.hasPriceGuarantee,
                    title: this.$translate('insurance_price_guarantee', 'Prisgaranti')
                },
                {
                    exist: this.cardModel.hasComprehensiveInsurance,
                    title: this.$translate('insurance_comprehensive', 'Allriskförsäkring')
                },
                {
                    exist: this.cardModel.hasCancellationProtection,
                    title: this.$translate('insurance_cancellation_protection', 'Avbeställningsskydd')
                },
                {
                    exist: this.cardModel.hasDeductibleInsurance,
                    title: this.$translate('insurance_deductible', 'Självriskreducering')
                },
                {
                    exist: this.cardModel.hasLostKeyInsurance,
                    title: this.$translate('insurance_lost_key', 'Nyckelförsäkring')
                },
                {
                    exist: this.cardModel.hasWrongFuelInsurance,
                    title: this.$translate('insurance_wrong_fuel', 'Feltankningsförsäkring')
                },
                {
                    exist: this.cardModel.hasCancelledEventInsurance,
                    title: this.$translate('insurance_cancelled_event', 'Evenemangsförsäkring')
                },
                {
                    exist: this.cardModel.hasHoleInOneInsurance,
                    title: this.$translate('insurance_hole_in_one', 'Hole-in-one försäkring')
                },
                {
                    exist: this.cardModel.hasIdTheftInsurance,
                    title: this.$translate('insurance_id_theft', 'Id-skyddsförsäkring')
                },
            ];
        },
        isInsured () {
            return this.insurances.filter(insurance => insurance.exist);
        },
        isNotInsured () {
            return this.insurances.filter(insurance => !insurance.exist);
        }
    }
};
</script>