<template>
    <div class="slices">
        <template v-for="(slice, index) in slices">
            <BannerCard v-if="slice.slice_type === 'banner'" :key="index" :data="slice" />
            <BannerLink v-else-if="slice.slice_type === 'banner__link'" :key="index" :data="slice" />
            <BannerExpanders v-else-if="slice.slice_type === 'banner__expanders'" :key="index" :data="slice" :slices="slices" />
            <BannerImage v-else-if="slice.slice_type === 'banner__image'" :key="index" :data="slice" />
            <InfoBox v-else-if="slice.slice_type === 'info_box'" :key="index" :data="slice" />
            <ListAllCards v-else-if="slice.slice_type === 'list__all_cards'" :key="index" :data="slice" />
            <ListAllPosts v-else-if="slice.slice_type === 'list__all_posts'" :key="index" :data="slice" />
            <ListAllAuthors v-else-if="slice.slice_type === 'list__all_authors'" :key="index" :data="slice" />
            <ListSelectedCards v-else-if="slice.slice_type === 'list__cards'" :key="index" :data="slice" />
            <ListCardsCategory v-else-if="slice.slice_type === 'list__card_category'" :key="index" :data="slice" />
            <ListBestCards v-else-if="slice.slice_type === 'list__best_per_category'" :key="index" :data="slice" />
            <ListLenders v-else-if="slice.slice_type === 'list_lenders'" :key="index" :data="slice" />
            <RichText v-else-if="slice.slice_type === 'rich_text'" :key="index" :data="slice" />
            <TwoBoxes v-else-if="slice.slice_type === 'two_boxes'" :key="index" :data="slice" />
            <Faq v-else-if="slice.slice_type === 'faq'" :key="index" :data="slice" />
            <ContactUs v-else-if="slice.slice_type === 'contact_us'" :key="index" :data="slice" />
            <Media v-else-if="slice.slice_type === 'media_section'" :key="index" :data="slice" />
            <CompareCards v-else-if="slice.slice_type === 'compare_cards'" :key="index" :data="slice" />
            <Indexed v-else-if="slice.slice_type === 'indexed_section'" :key="index" :data="slice" />
            <CardGuide v-else-if="slice.slice_type === 'credit_card_guide'" :key="index" :data="slice" />
            <ContactTable v-else-if="slice.slice_type === 'contact_table'" :key="index" :data="slice" />
            <Sitemap v-else-if="slice.slice_type === 'sitemap'" :key="index" :data="slice" />
            <ComparisonTable v-else-if="slice.slice_type === 'comparison_table'" :key="index" :data="slice" />
            <HighlightStatistics v-else-if="slice.slice_type === 'highlight_statistics'" :key="index" :data="slice" />
            <BankCooperations v-else-if="slice.slice_type === 'bank_cooperations'" :key="index" :data="slice" />
            <Table v-else-if="slice.slice_type === 'table'" :key="index" :data="slice" />
            <HighlightedCard v-else-if="slice.slice_type === 'highlighted_card'" :key="index" :data="slice" />
            <TableOfContents v-else-if="slice.slice_type === 'table_of_contents'" :key="index" :data="slice" :slices="slices" />
            <TableOfContentsLink v-else-if="slice.slice_type === 'table_of_contents_link'" :key="index" :data="slice" />
            <Quote v-else-if="slice.slice_type === 'quote'" :key="index" :data="slice" />
            <AlternateGrid v-else-if="slice.slice_type === 'alternate_grid'" :key="index" :data="slice" />
            <CenteredImage v-else-if="slice.slice_type === 'centered_image'" :key="index" :data="slice" />
            <HighlightedDocuments v-else-if="slice.slice_type === 'highlighted_documents'" :key="index" :data="slice" />
            <Guides v-else-if="slice.slice_type === 'guides'" :key="index" :data="slice" :is-first="index === 0" />
            <Button v-else-if="slice.slice_type === 'button'" :key="index" :data="slice" />
            <BigList v-else-if="slice.slice_type === 'big_list'" :key="index" :data="slice" />
            <PagesByTag v-else-if="slice.slice_type === 'pages_by_tag'" :key="index" :data="slice" :document-id="document.id" />
        </template>
    </div>
</template>
<script>
// import Debug from '@/components/Debug.vue';

// Banners
import BannerCard from '@/components/slices/banners/BannerCard.vue';
import BannerLink from '@/components/slices/banners/BannerLink.vue';
import BannerExpanders from '@/components/slices/banners/BannerExpanders.vue';
import BannerImage from '@/components/slices/banners/BannerImage.vue';

// Lists
import ListAllCards from '@/components/slices/lists/AllCards.vue';
import ListAllAuthors from '@/components/slices/lists/AllAuthors.vue';
import ListAllPosts from '@/components/slices/lists/AllPosts.vue';
import ListSelectedCards from '@/components/slices/lists/SelectedCards.vue';
import ListCardsCategory from '@/components/slices/lists/CardsCategory.vue';
import ListBestCards from '@/components/slices/lists/BestCards.vue';
import ListLenders from '@/components/slices/lists/ListLenders.vue';

import InfoBox from '@/components/slices/InfoBox.vue';
import RichText from '@/components/slices/RichText.vue';
import TwoBoxes from '@/components/slices/TwoBoxes.vue';
import Faq from '@/components/slices/Faq.vue';
import Media from '@/components/slices/Media.vue';
import CompareCards from '@/components/slices/CompareCards.vue';
import Indexed from '@/components/slices/Indexed.vue';
import CardGuide from '@/components/slices/CardGuide.vue';
import ContactTable from '@/components/slices/ContactTable.vue';
import ComparisonTable from '@/components/slices/ComparisonTable.vue';
import HighlightedCard from '@/components/slices/HighlightedCard.vue';
import ContactUs from '@/components/slices/ContactUs.vue';
import HighlightStatistics from '@/components/slices/HighlightStatistics.vue';
import BankCooperations from '@/components/slices/BankCooperations.vue';
import Table from '@/components/slices/tables/Table.vue';
import TableOfContents from '@/components/slices/table-of-contents/ContentContainer.vue';
import TableOfContentsLink from '@/components/slices/table-of-contents/ContentLink.vue';
import Quote from '@/components/slices/Quote.vue';
import HighlightedDocuments from '@/components/slices/HighlightedDocuments.vue';
import Sitemap from './Sitemap.vue';
import Guides from './Guides.vue';
import AlternateGrid from './AlternateGrid.vue';
import CenteredImage from './CenteredImage.vue';
import Button from './Button.vue';
import BigList from '@/components/slices/BigList.vue';
import PagesByTag from '@/components/slices/pages-by-tag/index.vue';

export default {
    components: {
        // Debug,
        BannerCard, 
        BannerLink,
        BannerExpanders,
        BannerImage,
        ListAllCards, 
        ListAllAuthors, 
        ListAllPosts, 
        ListSelectedCards,
        ListCardsCategory, 
        ListBestCards,
        ListLenders,
        InfoBox,
        RichText,
        TwoBoxes,
        Faq,
        ContactUs,
        Media,
        CompareCards,
        Indexed,
        CardGuide,
        ContactTable,
        Sitemap,
        ComparisonTable,
        BankCooperations,
        HighlightStatistics,
        Table,
        HighlightedCard,
        TableOfContents,
        TableOfContentsLink,
        Quote,
        AlternateGrid,
        CenteredImage,
        HighlightedDocuments,
        Guides,
        Button,
        BigList,
        PagesByTag,
    },
    props: {
        slices: {
            type: Array,
            required: true
        },
        document: {
            type: Object,
            required: false,
            default: null
        }
    }
};
</script>
