<template>
    <tr>
        <th>{{ $translate('cons', 'Nackdelar') }}</th>
        <td v-for="(card, index) in cards" :key="index">
            <ul>
                <li v-for="(con, i) in card.data.cons_list" :key="i" class="list-checkmarks margin-bottom-2">
                    <i v-if="con.con" class="fas fa-times-circle red" /><p>{{ con.con }}</p>
                </li>
            </ul>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        cards: {
            type: Array,
            required: true
        },
    }
};
</script>