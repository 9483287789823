<template>
    <tr>
        <th>{{ rowContent.header }}</th>
        <td v-for="(value, i) in rowContent.data" :key="i"> 
            <div class="list-checkmarks">
                <i v-if="value.checkmark !== 'none'" :class="value.checkmark === 'check-mark' ? 'fas fa-check-circle green' : 'fas fa-times-circle red'" />
                <div v-html="$prismic.asHtml(value.text)" />
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    props: {
        rowContent: {
            type: Object,
            required: true
        }
    }
};
</script>