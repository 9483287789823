<template>
    <div id="contact" class="two-boxes width-full flex flex-stretch">
        <div class="container flex text-center bg-slgray">
            <h2 class="green">{{ $translate('contact_information', 'Kontaktuppgifter') }}</h2>

            <ul class="width-full">
                <li 
                    v-for="(info, index) in contactInformation"
                    :key="index"
                    class="flex flex-keep"
                > 
                    <span class="flex-1 text-right text-bold margin-right-4">{{ info.title }}:</span>
                    <span class="flex-1 text-left text-lighter">{{ info.value }}</span>
                </li>
            </ul>
        </div>

        <div class="container bg-green flex text-center">
            <h2 class="white">{{ $translate('cr_opening_hours', 'Öppettider') }}</h2>

            <ul class="width-full white">
                <li 
                    v-for="(day, index) in openingHours"
                    :key="index"
                    class="flex flex-keep"
                > 
                    <span class="flex-1 text-right text-bold margin-right-4">{{ day.title }}:</span>
                    <span class="flex-1 text-left" :class="day.hours && 'text-lighter'">{{ day.hours ? day.hours : '-' }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        cardModel: {
            type: Object,
            required: true
        }
    },
    computed: {
        contactInformation(){ 
            return [
                {
                    title: this.$translate('contact_company_name', 'Företagsnamn'),
                    value: this.cardModel.company.name
                },
                {
                    title: this.$translate('contact_org_number', 'Org.nummer'),
                    value: this.cardModel.company.registrationNumber
                },
                {
                    title: this.$translate('contact_adress', 'Adress'), 
                    value: this.cardModel.company.addres
                },
                {
                    title: this.$translate('contact_zip_code', 'Postnummer'),
                    value: this.cardModel.company.postalNumber
                },
                {
                    title: this.$translate('contact_place', 'Ort'),
                    value: this.cardModel.company.county
                },
                {
                    title: this.$translate('phone', 'Telefon'),
                    value: this.cardModel.company.phone
                },
                {
                    title: this.$translate('email', 'E-post'),
                    value: this.cardModel.company.email
                }
            ];
        },
        openingHours(){
            return [
                {
                    title: this.$translate('day_monday', 'Måndag'),
                    hours: this.cardModel.openingHours.monday
                },
                {
                    title: this.$translate('day_tuesday', 'Tisdag'),
                    hours: this.cardModel.openingHours.tuesday 
                },
                {
                    title: this.$translate('day_wednesday', 'Onsdag'),
                    hours: this.cardModel.openingHours.wednesday
                },
                {
                    title: this.$translate('day_thursday', 'Torsdag'),
                    hours: this.cardModel.openingHours.thursday
                },
                {
                    title: this.$translate('day_friday', 'Fredag'),
                    hours: this.cardModel.openingHours.friday
                },
                {
                    title: this.$translate('day_saturday', 'Lördag'),
                    hours: this.cardModel.openingHours.saturday
                },
                {
                    title: this.$translate('day_sunday', 'Söndag'),
                    hours: this.cardModel.openingHours.sunday
                }
            ];
        }
    }
};
</script>