<template>
    <tr>
        <th>{{ payment.title }}</th>
        <td v-for="(card, index) in cards" :key="index" v-html="checkmarkBoolean(card.model[payment.key])" />
    </tr>
</template>

<script>
export default {
    props: {
        payment: {
            type: Object,
            required: true
        },
        cards: {
            type: Array,
            required: true
        },
        checkmarkBoolean: {
            type: Function,
            required: true
        }
    }
};
</script>
