<template>
    <div
        class="cta-fixed padding-2 border-bottom width-full bg-white flex"
    >
        <div class="inner flex flex-keep">
            <ButtonTarget
                size="md"
                background="orange"
                :link="document"
                placement="sticky"
                :text="$translate('button_apply', 'Till ansökan')"
                icon="fas fa-lock white"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    }
};
</script>