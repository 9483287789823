<template>
    <div class="section section-highlight-stats bg-blue">
        <div class="section-inner section-width-md">
            <div class="stat-container width-full col-divider col-divider-rotated text-center">
                <div 
                    v-for="(item, index) in data.items" 
                    :key="index"
                    class="stat-inner-container"
                >
                    <div class="highlight-stat">{{ item.statistic }}</div>
                    <div class="stat-title">{{ item.title }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    }
};
</script>