<template>
    <Section
        class="list-cards"
        width="md"
        :class="'bg-' + data.primary.background"
    >
        <h2 v-if="$helpers.filterEmptyElements(data.primary.h2)" class="section-header green m-center">{{ $prismic.asText(data.primary.h2) }}</h2>
        <div v-if="$helpers.filterEmptyElements(data.primary.teaser)" class="section-header width-full" v-html="$prismic.asHtml(data.primary.teaser)" />

        <div class="container">
            <Card
                v-for="(card, index) in cardsToShow"
                :key="index"
                :card="card"
                placement="list"
                :position="index + 1"
            />
        </div>
        <trust-us v-if="!data.primary.disable_trust_us" />
    </Section>
</template>

<script>
import Card from '@/components/cards/Card.vue';
import TrustUs from '@/components/TrustUs.vue';
export default {
    components: {
        Card,
        TrustUs
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        autoOrder: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            hasList: typeof this.data.primary.predefined_list.id !== 'undefined'
        };
    },
    computed: {
        cards() {
            if (this.autoOrder) {
                return this.orderedCards;
            }

            return this.selectedCards
                .map(iterator => iterator.card);
        },
        cardsToShow() {
            return this.cards
                .map(card => this.$store.state.cards[card.id])
                .filter(card => ! card.data.lists_disabled);
        },
        selectedCards() {
            if (this.hasList) {
                if (this.$store.state.lists[this.data.primary.predefined_list.id]) {
                    return this.$store.state.lists[this.data.primary.predefined_list.id].data.cards;
                }

                return [];
            }

            return this.data.items.filter(this.$helpers.filterEmptyRelations);
        },
        orderedCards() {

            const arr = [];
            let maxSalesCount = 0;
            let maxConversionRate = 0;
            let maxEpc = 0;

            this.selectedCards.forEach(iterator => {
                const card = this.$store.state.cards[iterator.card.id];
                const model = new this.$models.CreditCard(card);

                maxEpc = model._core.sales_stats.epc > maxEpc ? model._core.sales_stats.epc : maxEpc;
                maxSalesCount = model._core.sales_stats.sales > maxSalesCount ? model._core.sales_stats.sales : maxSalesCount;
                maxConversionRate = model._core.sales_stats.conversion_rate > maxConversionRate ? model._core.sales_stats.conversion_rate : maxConversionRate;
                arr.push(card);
            });

            arr.forEach(card => {
                const model = new this.$models.CreditCard(card);
                card.order =
                    (2 * (model._core.sales_stats.epc / maxEpc)) +
                    model._core.sales_stats.sales / maxSalesCount +
                    model._core.sales_stats.conversion_rate / maxConversionRate;
            });

            return arr.slice(0).sort((a, b) => b.order - a.order);

        },
    },
    mounted() {

        // NOTE: Leaving this for now just to be able to debug things later
        // if (process.env.APP_DEBUG === 'TRUE') {
        //     console.group('Default');
        //     this.cards.forEach(iterator => {
        //         console.log(this.$store.state.cards[iterator.card.id].data.title);
        //     });
        //     console.groupEnd();

        //     console.group('Auto order');
        //     const table = [];
        //     table.push([
        //         'title', 'epc', 'cvr', 'sales', 'score'
        //     ]);
        //     this.orderedCards.forEach(card => {
        //         const model = new this.$models.CreditCard(card);
        //         table.push([
        //             card.data.title,
        //             model._core.sales_stats.epc,
        //             this.$format.percentage(model._core.sales_stats.conversion_rate * 100),
        //             model._core.sales_stats.sales,
        //             card.order
        //         ]);
        //     });
        //     console.table(table);
        //     console.groupEnd();
        // }
    }
};
</script>

<style lang="scss" scoped>
.container {
    display: grid;
    gap: 40px;
    grid-template-columns: repeat( 1, 1fr );
}
</style>
