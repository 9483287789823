<template>
    <div 
        class="card-body"
        :class="[`card-body-${categoryItem.background_color}`, { 'active': cardCategoryClicked }]"
        @click="categoryClicked(categoryItem.filter_tag)"
    >
        <i v-show="cardCategoryClicked" class="removeFilter fas fa-xmark fa-sm" />
        <span class="text capitalize-first-letter">{{ categoryItem.text }}</span>
        <prismic-image 
            v-if="categoryItem.image.url"
            :img="categoryItem.image"
            class="prismic-image" 
            w="120"
        />
        <img 
            v-else
            class="fallback-image" 
            src="/category-fallback-image.png" 
            alt="card"
        >
    </div>
</template>

<script>
export default {
    props: {
        categoryItem: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cardCategoryClicked: this.categoryItem.active
        };
    },
    methods: {
        categoryClicked(categoryTag) {
            this.cardCategoryClicked = !this.cardCategoryClicked;
            this.$emit('categoryTag', categoryTag);
        }
    },
};
</script>